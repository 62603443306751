
// Using a functional component, you must wrap it in React.forwardRef, and then forward the ref to
// the node you want to be the root of the print (usually the outer most node in the ComponentToPrint)

import React from "react";
import { API, styles } from "./Constant";
import Header from "./Header";
import OrgDetails from "./OrgDetails";
import OthersInfo from "./OthersInfo";
import PersonalInfo from "./PersonalInfo";
import defaultImg from './profile.png';
import PropertyDetails from "./PropertyDetails";
import Logo from './we_tech-logo.png';

// https://reactjs.org/docs/refs-and-the-dom.html#refs-and-function-components
export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} style={styles.page}>
      <Header profile={props.profile} proType={props.proType} />
      <div style={styles.container}>
        <div style={styles.leftColumn}>
          <div style={styles.container}>
          {props.profile.u_pro_img ? 
            <img
            style={styles.image}
            src={`${API}/${props.profile.u_pro_img}`}
            alt="image"
          />
          :
          <img
          style={styles.image}
          src={defaultImg}
          alt="image"
        />
        }
          
          </div>
         <PersonalInfo profile={props.profile} proType={props.proType} dob={props.dob} />
        </div>

        <div style={styles.rightcontainer}>
          <PropertyDetails   profile={props.profile} proType={props.proType} />
          <OrgDetails   profile={props.profile} proType={props.proType} />
          <OthersInfo   profile={props.profile} proType={props.proType} />
        </div>
        
      </div>
      <div style={styles.footer}>
          <div style={styles.footerWrapper}>
          <b> Powered By  </b>
           <img style={styles.footerLogo} src={Logo} alt='logo' />

          </div>
        </div>
    </div>
  );
});