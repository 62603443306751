import React from 'react';
import { styles } from './Constant';

const OrgDetails =  ({proType,profile}) => {
  let poliOrgs=[]
  let nonPoliOrgs=[]
  let othersOrgs=[]
  let blankOrgs=[]
if(profile?.organizations){
   poliOrgs = profile.organizations.filter((item)=> item.org_poli_type==="political");
   nonPoliOrgs = profile.organizations.filter((item)=> item.org_poli_type==="non-political");
   othersOrgs = profile.organizations.filter((item)=> item.org_poli_type==="others");
   blankOrgs = profile.organizations.filter((item)=> item.org_type==="owner" || item.org_type==="labour" || item.org_type==="ministry" || item.org_type==="hindu");

}
//console.log(proType)
const getName = (name,religion)=>{
  if(name){
    return name
  }else if(religion === "হিন্দু"){
    return religion
  }else{
    return 'সকল'
  }
}
 
  return (
    <div style={styles.entryContainer}>
      <div style={styles.headerContainer}>
        <div style={styles.leftColumn2}>
          <h4 style={styles.subHeader}> সংগঠন সংক্রান্ত </h4>
        </div>
      </div>
 {
  proType==='religion' && profile.organizations.length>0 && (
     <div style={{display:'flex', flexDirection:'row',}}>

     <div style={{flex:'1'}}>
     
     <h4 style={styles.tag}> রাজনৈতিক </h4>

     <ul style={{flexDirection:'column',marginLeft: '1.5rem'}}>

     {poliOrgs && poliOrgs.map((item)=> (
      <li style={styles.list}>
      <h4 style={styles.value}> {item.org_name}  </h4>
      </li>
     ))}

     </ul>
     </div>

     <div style={{flex:1}}>

     <h4 style={styles.tag}> অরাজনৈতিক </h4>

     <ul style={{flexDirection:'column',marginLeft: '1.5rem'}}>
     {nonPoliOrgs && nonPoliOrgs.map((item)=> (
      <li style={styles.list}>
      <h4 style={styles.value}> {item.org_name}  </h4>
      </li>
     ))}
     </ul>
     </div>

     </div>
  )
 } 

 {blankOrgs.length>0 && profile.organizations.length>0 && (
  <>
  <h4 style={styles.tag}> {`${getName(profile?.u_type,profile?.u_religion)} সংগঠন`}  </h4>
    <ul style={{flexDirection:'column',marginLeft: '1.5rem'}}>
    {blankOrgs && blankOrgs.map((item)=> (
     <li style={styles.list}>
     <h4 style={styles.value}> {item.org_name}  </h4>
     </li>
    ))}
    </ul>
  </>
  
  )}

     {othersOrgs.length>0 && profile.organizations.length>0 && (
      <>
      <h4 style={styles.tag}> অন্যান্য </h4>
        <ul style={{flexDirection:'column',marginLeft: '1.5rem'}}>
        {othersOrgs && othersOrgs.map((item)=> (
         <li style={styles.list}>
         <h4 style={styles.value}> {item.org_name}  </h4>
         </li>
        ))}
        </ul>
      </>
      
      )}
      {profile.organizations.length===0 && (
       
        <h4 style={styles.tag}> জড়িত নয় </h4>
        
        
        )}
   

    </div>
  );
};

export default OrgDetails