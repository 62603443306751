const profile = {
  "u_id": 182,
  "u_name": "মাওলানা আব্দুল কুদ্দুস ",
  "u_pro_img": "uploads/image-1668678504630.JPG",
  "u_father_name": "কোনো তথ্য নাই",
  "u_mother_name": "undefined",
  "u_no_of_children": "4",
  "u_education": "দাওরায়ে হাদিস",
  "u_dob": "0000-00-00",
  "u_nationality": "বাংলাদেশী",
  "u_religion": "ইসলাম",
  "u_profession": "নায়েবে আমীর, হেফাজতে ইসলাম বাংলাদেশ ও মুহতামিম, জামিয়া আরাবিয়া ইমদাদুল উলুম ফরিদাবাদ মাদ্রাসা।",
  "u_gender": "পুরুষ",
  "u_married_status": "বিবাহিত",
  "u_p_division": "চট্টগ্রাম",
  "u_p_district": "কুমিল্লা",
  "u_p_upozilla": "বরুড়া",
  "u_p_post_code": "কোনো তথ্য নাই",
  "u_p_others": "গ্রাম: মোড়া বাজার, উপজেলা: বরুড়া, জেলা: কুমিল্লা।",
  "u_t_division": "ঢাকা",
  "u_t_district": "ঢাকা",
  "u_t_upozilla": "কোনো তথ্য নাই",
  "u_t_post_code": "কোনো তথ্য নাই",
  "u_t_others": "২৫/গ/১, গোলাপবাগ, ঢাকা-১২০৩।",
  "u_nid": 1234567890,
  "u_nid_img": "",
  "u_passport": "কোনো তথ্য নাই",
  "u_passport_img": "",
  "u_d_license": "কোনো তথ্য নাই",
  "u_d_license_img": "",
  "u_tin": "কোনো তথ্য নাই",
  "u_tin_img": "",
  "u_phone1": 1675774451,
  "u_phone2": 1976665155,
  "u_fb_link": "কোনো তথ্য নাই",
  "u_twitter_lnk": "কোনো তথ্য নাই",
  "u_whatsapp_no": 1675774451,
  "u_sthabor_property": "কোনো তথ্য নাই",
  "u_osthabor_property": "কোনো তথ্য নাই",
  "u_family_info": "সন্তানাদি: ০৩ ছেলে, ০১ মেয়ে।\nছেলে: \n০১। মো: শরীফ, ব্যবসায়ী, ০১৬৭৫-৭৭৪৪৫১।\n০২। জুবায়ের আহমেদ, শিক্ষক (ফরিদাবাদ মাদ্রাসা), ০১৯৭৬-৬৬৫১৫৫।\n০৩। আব্দুল ওবায়েদ, শিক্ষক (ফরিদাবাদ মাদ্রাসা)।\n",
  "u_others_org": "undefined",
  "u_anual_income": "কোনো তথ্য নাই",
  "u_tax": "কোনো তথ্য নাই",
  "u_mamla": "কোনো তথ্য নাই",
  "u_car": "গাড়ী নম্বর: ঢাকা মেট্রো-গ-৩২-৮৯৯৭",
  "u_travel": "কোনো তথ্য নাই",
  "u_political": "কোনো তথ্য নাই",
  "u_election": "কোনো তথ্য নাই",
  "u_influence_org": "কোনো তথ্য নাই",
  "u_local_influence": "কোনো তথ্য নাই",
  "u_tablig": "কোনো তথ্য নাই",
  "u_frnds": "কোনো তথ্য নাই",
  "u_enemy": "কোনো তথ্য নাই",
  "u_connectivity": "কোনো তথ্য নাই",
  "u_corruption": "তার বিরুদ্ধে হাইয়াতুল উলিয়ার পরীক্ষার প্রশ্নফাঁসের অভিযোগ রয়েছে।",
  "u_negatives": "কোনো তথ্য নাই",
  "u_others": "কোনো তথ্য নাই",
  "u_comment": "ব্যক্তিবিষয় হেফাজতে ইসলাম বাংলাদেশ এর সাথে সম্পৃক্ত এবং হরকাতুল জিহাদ বিরোধী।",
  "organizations": [
      {
          "org_u_id": 753,
          "u_id": 182,
          "org_type": "islamic",
          "org_name": "বাংলাদেশ খেলাফত আন্দোলন",
          "org_poli_type": "political"
      },
      {
          "org_u_id": 754,
          "u_id": 182,
          "org_type": "islamic",
          "org_name": "হেফাজতে ইসলাম বাংলাদেশ",
          "org_poli_type": "non-political"
      },
      {
          "org_u_id": 755,
          "u_id": 182,
          "org_type": "islamic",
          "org_name": "বেফাক",
          "org_poli_type": "non-political"
      },
      {
          "org_u_id": 756,
          "u_id": 182,
          "org_type": "islamic",
          "org_name": "",
          "org_poli_type": "others"
      }
  ]
}
const API = 'https://api.biogrri.org/';
const styles = {

  page: {
    padding: 20,
    background:'white',
    color:'black',
    position:'relative',
    paddingBottom:'2rem',
  },
  header:{
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    alignItems: 'stretch',
    fontFamily:'Noto Serif Bengali'
  },
  detailColumn: {
    flexDirection: 'column',
    flexGrow: 9,
    textTransform: 'uppercase',
    fontFamily:'Noto Serif Bengali'
  },
  name: {
    fontSize: 24,
    fontFamily:'Noto Serif Bengali',
    marginBottom:'0px'
  },
  subtitle: {
    fontSize: 18,
    justifySelf: 'flex-end',
    fontFamily:'Noto Serif Bengali',
    margin:0
  },
  container: {
    display:'flex',
    flex: 1,
    flexDirection: "row",
    justifyContent:'center',
    marginBottom:10,
    "@media max-width: 400": {
      flexDirection: "column"
    }
  },
  image: {
    marginBottom: 10,
    marginBottom: "0px",
    width:'100%',
  },
  leftColumn: {
    flexDirection: "column",
    flex:0.25,
    paddingTop: 30,
    paddingRight: 15,
    "@media max-width: 400": {
      width: "100%",
      paddingRight: 0
    },
    "@media orientation: landscape": {
      width: 200
    }
  },
  footer: {
    fontSize: 10,
    color: "red",
    fontWeight: "bold",
    fontFamily: "Lato Bold",
    textAlign: "center",
    position:'absolute',
    bottom:2,
    right:25,
    display: "flex",
    justifyContent: "flex-end",
    "@media orientation: landscape": {
      marginTop: 10
    }
  },
  footerWrapper:{
    display:'flex',
   flexDirection:'column',
   justifySelf:'flex-end'
  },
  footerLogo:{
    width:25,
    height:25,
    margin:'0 auto'
  },
  rightcontainer: {
    flexDirection:'column',
    paddingTop: 30,
    flex: 0.75,
    paddingLeft: 15,
    "@media max-width: 400": {
      paddingTop: 10,
      paddingLeft: 0
    }
  },
  ///inner design

  innerContainer: {
    marginBottom: 5,
  },
  sectionContainer:{
    border:'1px dotted black',padding:3,marginTop:15,
  },
  subHeader:{
    fontFamily:'Noto Serif Bengali',
    fontSize:14,
    fontWeight:700,
    border:'1px solid black',
    textAlign:'center'
  },
  
  item:{
    display:'flex',
    flexDirection:'column',
    margin:0,
    paddingBottom:15,
    borderTop: '1px dotted black',
   },
   tag: {
     fontFamily: 'Noto Serif Bengali',
     fontSize: 15,
     fontWeight:700,
     margin:0,
     padding:0,
     textAlign:'center',
     textDecoration:'underline'
   },
   value:{
     fontFamily:'Noto Serif Bengali',
     fontSize:14,
     fontWeight:500,
     textAlign:'left',
     marginLeft:5,
     padding:0,
     whiteSpace:'pre-wrap',
     display:'table'
   },
    pagebreak:{ 
      pageBreakBefore: 'always'
    } /* page-break-after works, as well */

    ,
    //right side styling
    entryContainer: {
      marginBottom: 10,
      border:'1px dotted black',
      padding:4,
      display:'block',
    },
    list:{
      fontSize: 11,
    fontFamily: 'Noto Serif Bengali',
    listStyle: 'disc',
    },
    leftColumn2: {
      flexDirection: "column",
      flex:0.25,
      paddingTop: 5,
      paddingRight: 15,
      "@media max-width: 400": {
        width: "100%",
        paddingRight: 0
      },
      "@media orientation: landscape": {
        width: 200
      }
    },

};
export { profile, API, styles };

