import React from 'react'
import { styles } from './Constant'

const PropertyDetails = ({profile,proType}) => {
  return (
    <div style={styles.entryContainer}>
    <div style={styles.headerContainer}>
      <div style={styles.leftColumn2}>
        <h4 style={styles.subHeader}> সম্পদের বিবরন </h4>
      </div>
    </div>
    <div style={styles.item}>
    <h4 style={styles.tag}> স্থাবর সম্পদ </h4>
    <h4 style={styles.value}>     {profile.u_sthabor_property}    </h4>
    </div>

    <div style={styles.item}>
    <h4 style={styles.tag}> অস্থাবর সম্পদ </h4>
    <h4 style={styles.value}>    {profile.u_osthabor_property}     </h4>
    </div>


  </div>
  )
}

export default PropertyDetails