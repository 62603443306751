import React from "react";

// import titlelineimg from "../../../Assets/icons/title-line-image-2.png";
import userImg from "../../Assets/Image/userimg.png";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";

import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { IMAGE_BASE_URL } from "../../Utils/constants";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  duration: 1000,
};
const RMGProfileCard = ({
  name,
  designation,
  phone,
  email,
  address,
  id,
  nid,
  img,
  type,
  deleteProfile,
  alldata,
  pinprofileData,
  setPinProfileData,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let admintype = localStorage.getItem("is_super_admin");
  const handlepinProfile = () => {
    // //console.log(alldata, "alldata");
    // const revisedData =allData.filter((data)=> data.u_id!==targetId)
    let pinArr = [];
    if (pinprofileData.find((item) => item.u_id === alldata.u_id)) {
      const revisedData = pinprofileData.filter(
        (data) => data.u_id !== alldata.u_id
      );
      setPinProfileData(revisedData);
      if (type === "অন্যান্য") {
        localStorage.setItem(`অন্যান্যrmg`, JSON.stringify(revisedData));
      } else {
        localStorage.setItem(`${type}`, JSON.stringify(revisedData));
      }
    } else {
      pinArr = [...pinprofileData, alldata];
      setPinProfileData(pinArr);
      if (type === "অন্যান্য") {
        localStorage.setItem(`অন্যান্যrmg`, JSON.stringify(pinArr));
      } else {
        localStorage.setItem(`${type}`, JSON.stringify(pinArr));
      }
    }
    //console.log(pinprofileData, "pinprofileData");
    // pinArr = [...pinprofileData, {...alldata,clicked:true} ];

    // //console.log(pinArr, "pinArr");
  };
  return (
    <div className="container">
      <div
        className={` ${
          type === "মালিক"
            ? "ownercard"
            : type === "শ্রমিক"
            ? "labourcard"
            : type === "মন্ত্রনালয়"
            ? "ministrycard"
            : "otherscard"
        }  rounded-xl hover:translate-y-1 duration-700   shadow-md shadow-slate-500 p-3 mt-20 `}
      >
        <div className=" ">
          <div className=" grid place-items-center -mt-14 ">
            {img ? (
              <img
                className=" w-24 h-24 z-20  bg-imgcolor rounded-full"
                src={`${IMAGE_BASE_URL}/${img}`}
                alt="profileimg"
              />
            ) : (
              <img
                className=" w-24 h-24 z-20 rounded-full"
                src={userImg}
                alt="profileimg"
              />
            )}
          </div>
          <div className="h-52 overflow-y-auto scrollbar-hide ">
            <div className=" flex justify-start items-center gap-x-5 text-lg my-2">
              <button className=" ">
                <AccountCircleOutlinedIcon />
              </button>
              <p className="text-white font-medium text-sm xl:text-base">
                {name}
              </p>
            </div>
            <div className=" flex justify-start items-center gap-x-5  my-2">
              <button className=" ">
                <WorkOutlineIcon />
              </button>
              <p className="text-white font-medium text-sm xl:text-base">
                {designation}
              </p>
            </div>

            <div className=" border-b-2 mr-7 my-2"></div>
            {/* <div className=" flex justify-start items-center gap-x-5  ">
              <button className=" ">
                <WorkOutlineIcon />
              </button>
              <p className="text-white font-medium text-sm xl:text-base">
                {religion}
              </p>
            </div> */}
            <div className=" flex justify-start items-center gap-x-5 my-2">
              <button className=" ">
                <PhoneAndroidOutlinedIcon />
              </button>
              <p className="text-white font-medium text-sm xl:text-base">
                0{phone}
              </p>
            </div>
            <div className=" flex justify-start items-center gap-x-5 mb-2">
              <button className="">
                <BadgeOutlinedIcon />
              </button>
              <p className="text-white font-medium text-sm xl:text-base">
                {nid}
              </p>
            </div>
            <div className=" flex justify-start items-center gap-x-5 mb-2">
              <button className="">
                <HomeOutlinedIcon />
              </button>
              <p className="text-white font-medium text-sm xl:text-base">
                {address}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="overlay rounded-xl z-0    shadow-md shadow-slate-500 ">
        <div className="blurcard">
          <div className=" hover:text-btncolor active:text-violet-700 text-right p-2 cursor-pointer">
            <PushPinOutlinedIcon onClick={handlepinProfile} fontSize="medium" />
          </div>
          <div className=" vertical-center ">
            <Link to={`/rmg_Profile_details/${id}`}>
              {" "}
              <button className=" text-sm md:text-lg whitespace-nowrap commonbtn">
                বিস্তারিত দেখুন
              </button>
            </Link>
          </div>
          <div className="delete-center">
            {admintype === "yes" ? (
              <button
                onClick={handleOpen}
                className=" text-sm md:text-base whitespace-nowrap rounded-xl px-3 py-2 bg-indigo-500 hover:bg-red-500 "
              >
                ডিলিট করুন
              </button>
            ) : null}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className=" rounded-2xl ">
                <div
                  onClick={handleClose}
                  className=" cursor-pointer  text-end  text-sky-500 hover:text-red-500 "
                >
                  <CloseIcon fontSize="large" />
                </div>
                <p className=" text-black">
                  আপনি কি নিশ্চিত! এই প্রোফাইল টি ডিলিট করতে চান?
                </p>
                <div className=" flex items-end justify-end gap-x-3">
                  <button
                    onClick={() => {
                      handleClose();
                      deleteProfile(id);
                    }}
                    className=" text-sm md:text-base whitespace-nowrap rounded-2xl px-3 py-2 bg-indigo-500 hover:bg-red-500 "
                  >
                    ডিলিট
                  </button>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RMGProfileCard;
