import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FactoryIcon from "@mui/icons-material/Factory";
import GroupsIcon from "@mui/icons-material/Groups";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PublicIcon from "@mui/icons-material/Public";
import SynagogueIcon from "@mui/icons-material/Synagogue";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getAdminApi } from "../../services/api.service";
import ShowSidebar from "./ShowSidebar";
import "./Sidebar.css";
const ignoreArr = [
  "/",
  "/forget_PassWord",
  "/pdfview/rmg",
  "/pdfview/religion",
  "/pdfview/ir",
];
const Sidebar = ({ loginStatus }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [notificationData, setNotificationData] = useState(0);
  const location = useLocation();

  // //console.log("pathname", location.pathname);
  let admintype = localStorage.getItem("is_super_admin");

  const getDataApiCall = async () => {
    //console.log("heatin useeffet");
    let notiNo = 0;
    try {
      // //console.log("try er moddhe");
      const data = await getAdminApi(`admin/noti`);
      if (data.error === true) {
        // toast.warning("Something went wrong");
        return null;
      }
      //console.log(data.data);
      data.data.map((item) => {
        // for (let i = 0; i < data?.data?.length; i += 1) {}
        if (item.n_read_status === "no") {
          //console.log("true");
          notiNo = notiNo + 1;
        }
      });

      //console.log(notiNo, "notiNo");
      setNotificationData(notiNo);
    } catch (err) {
      //console.log(err);
    }
  };
  useEffect(() => {
    const callApi = async () => {
      await getDataApiCall();
    };
    callApi();
  }, [showSidebar, loginStatus]);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  ////console.log(location.pathname)
  if (ignoreArr.includes(location.pathname)) {
    return null;
  }

  if (location.pathname.includes("pdfview")) {
    return null;
  }
  const handleLogout = () => {
    localStorage.clear();
  };
  return (
    <div className="z-50">
      <ShowSidebar
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
        toggleSidebar={toggleSidebar}
      />
      <div
        className={` sidebarbg h-screen fixed top-0 md:left-0 ${
          showSidebar ? "-left-72" : "left-0"
        } overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl text-white w-72 z-50 py-4 px-6 transition-all duration-300`}
      >
        <div className="  flex-col items-stretch min-h-full flex-nowrap px-0 relative">
          <div className="flex justify-between items-center">
            <div className="mt-2 text-center w-full inline-block">
              <h1 className=" text-white"> এডমিন ড্যাশবোর্ড</h1>
            </div>
            <div className={` md:hidden  z-50  transition-all duration-300`}>
              <button onClick={toggleSidebar}>
                <MenuOpenIcon color="secondary" fontSize="large" />
              </button>
            </div>
          </div>

          <div className="flex flex-col">
            <hr className="my-4 min-w-full" />
          </div>
          <div>
            <Link to="/dashboard">
              <button
                className=" text-white  uppercase flex justify-start items-center font-bold mb-4 gap-x-4 py-2 px-6 w-full"
                style={{
                  background:
                    location.pathname === "/dashboard" ? "#AEA0EE" : " #10a2c7",
                  borderRadius: " 4px",
                }}
                onClick={toggleSidebar}
              >
                <DashboardIcon /> ড্যাশবোর্ড
              </button>
            </Link>
            <Link to="/profile">
              <button
                onClick={toggleSidebar}
                className=" text-white  uppercase flex justify-start items-center font-bold mb-4 gap-x-4 py-2 px-6 w-full"
                style={{
                  background:
                    location.pathname === "/profile" ? "#AEA0EE" : " #10a2c7",
                  borderRadius: " 4px",
                }}
              >
                <AccountCircleIcon /> প্রোফাইল
              </button>
            </Link>

            <TreeView
              aria-label="controlled"
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              multiSelect
            >
              <TreeItem
                nodeId="1"
                className=" uppercase font-bold mb-4 text-left"
                label="সেকশন"
              >
                <Link to="/religions">
                  <button
                    onClick={toggleSidebar}
                    className=" text-white flex justify-start items-center uppercase text-left font-bold mb-2 mt-2  p-2  w-full"
                    style={{
                      background:
                        location.pathname === "/religions"
                          ? "#AEA0EE"
                          : " #10a2c7",
                      borderRadius: " 4px",
                    }}
                  >
                    <SynagogueIcon />
                    <TreeItem nodeId="2" label="ধর্মীয় "></TreeItem>
                  </button>
                </Link>
                <Link to="/rmg">
                  <button
                    onClick={toggleSidebar}
                    className=" text-white uppercase flex justify-start items-center text-left font-bold mb-2 mt-2  p-2  w-full"
                    style={{
                      background:
                        location.pathname === "/rmg" ? "#AEA0EE" : " #10a2c7",
                      borderRadius: " 4px",
                    }}
                  >
                    <FactoryIcon />
                    <TreeItem nodeId="7" label="আরএমজি" />
                  </button>
                </Link>
                <Link to="/irprofiles">
                  <button
                    onClick={toggleSidebar}
                    className="text-white uppercase flex justify-start items-center text-left font-bold mb-2 mt-2  p-2  w-full"
                    style={{
                      background:
                        location.pathname === "/irprofiles"
                          ? "#AEA0EE"
                          : " #10a2c7",
                      borderRadius: " 4px",
                    }}
                  >
                    <PublicIcon />
                    <TreeItem nodeId="8" label="আন্তর্জাতিক বিষয়ক" />
                  </button>
                </Link>
              </TreeItem>
            </TreeView>
            {admintype === "yes" ? (
              <Link to="/admins">
                <button
                  onClick={toggleSidebar}
                  className=" text-white   uppercase flex justify-start items-center font-bold my-4 gap-x-4 py-2 px-6 w-full"
                  style={{
                    background:
                      location.pathname === "/admins" ? "#AEA0EE" : " #10a2c7",
                    borderRadius: " 4px",
                  }}
                >
                  <GroupsIcon /> অ্যাডমিনস
                </button>
              </Link>
            ) : null}

            <div>
              {admintype === "yes" ? (
                <Link to="/notifications">
                  <button
                    className=" text-white  uppercase flex justify-start items-center font-bold mb-4 gap-x-4 py-2 pl-4  w-full"
                    style={{
                      background:
                        location.pathname === "/notifications"
                          ? "#AEA0EE"
                          : " #10a2c7",
                      borderRadius: " 4px",
                    }}
                    onClick={toggleSidebar}
                  >
                    <NotificationsActiveIcon /> নোটিফিকেশন{" "}
                    {notificationData !== 0 ? (
                      <span className=" text-lg bg-red-600 rounded-full w-8 h-8">
                        {notificationData}
                      </span>
                    ) : (
                      <span className=" text-lg bg-green-600 rounded-full w-8 h-8">
                        {notificationData}
                      </span>
                    )}
                    {/* <span className=" text-lg bg-red-600 rounded-full w-8 h-8">
                      {notificationData}
                    </span> */}
                  </button>
                  {/* <div className="">
                  <div className="firefox svg_icons">
                    <NotificationsActiveIcon />
                  </div>
                  <span className="e-badge e-badge-danger e-badge-overlap e-badge-notification e-badge-circle">
                    35
                  </span>
                </div> */}
                </Link>
              ) : null}

              <Link to="/">
                <button
                  onClick={handleLogout}
                  className=" text-white  uppercase flex justify-start items-center font-bold mb-4 gap-x-4 py-2 px-6 w-full"
                  style={{
                    background: " #10a2c7",
                    borderRadius: " 4px",
                  }}
                >
                  <LogoutIcon /> লগআউট
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
