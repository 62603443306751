import React from 'react';
import { styles } from './Constant';
import './pdfPageStyle.css';

const OthersInfo = ({proType,profile}) => {
  return (
    <div style={styles.entryContainer}>
      <div style={styles.headerContainer}>
        <div style={styles.leftColumn2}>
          <h4 style={styles.subHeader}> অন্যান্য তথ্য </h4>
        </div>
      </div>

      <div style={styles.item}>
      <h4 style={styles.tag}> বার্ষিক আয় এবং আয়ের উৎস </h4>
      <h4 style={styles.value}> {profile.u_anual_income}  </h4>
      </div>

      <div style={styles.item}>
      <h4 style={styles.tag}> আয়কর সংক্রান্ত তথ্য    </h4>
      <h4 style={styles.value}> {profile.u_tax}    </h4>
      </div>

      <div style={styles.item}>
      <h4 style={styles.tag}> বিদেশ ভ্রমণ সংক্রান্ত    </h4>
      <h4 style={styles.value}> {profile.u_travel}    </h4>
      </div>

      <div style={styles.item}>
      <h4 style={styles.tag}> মামলা সংক্রান্ত    </h4>
      <h4 style={styles.value}> {profile.u_mamla}   </h4>
      </div>

      <div style={styles.item}>
      <h4 style={styles.tag}> গাড়ি সংক্রান্ত    </h4>
      <h4 style={styles.value}> {profile.u_car}    </h4>
      </div>

      <div style={styles.item}>
      <h4 style={styles.tag}> রাজনৈতিক সংশ্লিষ্টতা      </h4>
      <h4 style={styles.value}> {profile.u_political}      </h4>
      </div>

      <div style={styles.item}>
      <h4 style={styles.tag}> নির্বাচনে অংশগ্রহণ       </h4>
      <h4 style={styles.value}> {profile.u_election}      </h4>
      </div>

      <div style={styles.item}>
      <h4 style={styles.tag}> সংগঠনের উপর প্রভাব       </h4>
      <h4 style={styles.value}> {profile.u_influence_org}    </h4>
      </div>

      {
        proType === "religion" && profile.u_religion && (
          <div style={styles.item}>
          <h4 style={styles.tag}> তাবলীগ জামাত সংক্রান্ত তথ্য       </h4>
          <h4 style={styles.value}> {profile.u_tablig}    </h4>
          </div>
        )
      }

      <div style={styles.item}>
      <h4 style={styles.tag}> বিরোধী ব্যক্তিদের নাম      </h4>
      <h4 style={styles.value}> {profile.u_enemy}    </h4>
      </div>

      <div style={styles.item}>
      <h4 style={styles.tag}> ঘনিষ্ঠ মিত্রদের নাম      </h4>
      <h4 style={styles.value}> {profile.u_frnds} </h4>
      </div>

      <div style={styles.item} >
      <h4 style={styles.tag}> বহির্বিশ্বে যোগাযোগ </h4>
      <h4 style={styles.value}> {profile.u_connectivity}       </h4>
      </div>

      <div style={styles.item}>
      <h4 style={styles.tag}> নেতিবাচক বক্তব্য ও কর্মকান্ড      </h4>
      <h4 style={styles.value}> {profile.u_negatives}    </h4>
      </div>

      <div style={styles.item}>
      <h4 style={styles.tag}> স্থানীয় প্রভাব ও প্রতিপত্তি        </h4>
      <h4 style={styles.value}> {profile.u_local_influence}      </h4>
      </div>

      <div style={styles.item}>
      <h4 style={styles.tag}> নাশকতামূলক/দুর্নীতি/চরমপন্থী সংক্রান্ত তথ্যাদি </h4>
      <h4 style={styles.value}>    {profile.u_corruption}    </h4>
      </div>
    
      <div style={styles.item}>
      <h4 style={styles.tag}> পারিবারিক তথ্যাদি </h4>
      <p style={styles.value} >
      {profile.u_family_info}
      </p>
    
    
     </div>
      <div style={styles.item}>
      <h4 style={styles.tag}>  অন্যান্য তথ্যাদি </h4>
      <h4 style={styles.value}> {profile.u_others}     </h4>
      </div>

      <div style={styles.item}>
      <h4 style={styles.tag}> মন্তব্য </h4>
      <h4 style={styles.value}>{profile.u_comment}     </h4>
      </div>

    </div>
  );
};

export default OthersInfo