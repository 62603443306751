import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Sidebar from "./Components/Sidebar/Sidebar";
import AddAdmin from "./Pages/Admins/AddAdmin";
import Admins from "./Pages/Admins/Admins";
import ForgetPassword from "./Pages/AuthPage/ForgetPassword";
import Login from "./Pages/AuthPage/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import IrProfiles from "./Pages/IntAffairs/IrProfiles";
import Profile from "./Pages/Profile/Profile";
import AllReligion from "./Pages/Religions/AllReligion/AllReligion";
import AddBuddhaProfile from "./Pages/Religions/Buddha/AddBuddhaProfile";
import Buddha from "./Pages/Religions/Buddha/Buddha";
import AddCristanProfile from "./Pages/Religions/Cristan/AddCristanProfile";
import Cristan from "./Pages/Religions/Cristan/Cristan";
import AddHunduProfile from "./Pages/Religions/Hindhu/AddHunduProfile";
import Hindhu from "./Pages/Religions/Hindhu/Hindhu";
import Religions from "./Pages/Religions/index";
import AddIslamProfile from "./Pages/Religions/Islam/AddIslamProfile";
import Islam from "./Pages/Religions/Islam/Islam";
import AddOthersReligion from "./Pages/Religions/OthersReligion/AddOthersReligion";
import OthersReligion from "./Pages/Religions/OthersReligion/OthersReligion";
import GarmentsOwner from "./Pages/RMG/GarmentOwner/GarmentsOwner";
import RMG from "./Pages/RMG/Index";
import MinistryProfile from "./Pages/RMG/MinistryProfile/MinistryProfile";

// import PrivateRoute from "./Pages/AuthPage/PrivateRoutes";
import "animate.css";
import { useState } from "react";
import EditProfile from "./Components/Profile/EditProfile";
import ProfileDetails from "./Components/Profile/ProfileDetails";
import RMGEditProfile from "./Components/Profile/RMGEditProfile";
import RMGProfileDetails from "./Components/Profile/RMGProfileDetails";
import PrivateRoute from "./Pages/AuthPage/PrivateRoutes";
import AddIrProfiles from "./Pages/IntAffairs/AddIrProfiles";
import IREditProfile from "./Pages/IntAffairs/IREditProfile";
import IrProfileDetails from "./Pages/IntAffairs/IrProfileDetails";
import NotFoundPage from "./Pages/NotFoundPage";
import Notifications from "./Pages/Notifications/Notifications";
import PDFScreen from "./Pages/react-to-print/PDFScreen";
import AllRMG from "./Pages/RMG/AllRmg/AllRMG";
import AddGarmentsOwner from "./Pages/RMG/GarmentOwner/AddGarmentsOwner";
import AddgarmentsLabour from "./Pages/RMG/GarmentsLabor/AddgarmentsLabour";
import GarmentsLabor from "./Pages/RMG/GarmentsLabor/GarmentsLabor";
import AddMinistryProfile from "./Pages/RMG/MinistryProfile/AddMinistryProfile";
import AddOthersRmgProfile from "./Pages/RMG/OthersRMG/AddOthersRmgProfile";
import OthersRMG from "./Pages/RMG/OthersRMG/OthersRMG";
function App({ children }) {
  // ////console.log(getLocation("2"))
  // //console.log(getLocation("2","49"))
  //  //console.log(getLocation("2","49","Begumganj"))
  //  //console.log(getLocation())

  // //console.log(location.pathname, "location from appjs");
  // useEffect(() => {

  // }, [input]);
  const [loginStatus, setLoginStatus] = useState(false);
  return (
    <div>
      <div>
        <Sidebar loginStatus={loginStatus} />
        <Routes>
          <Route
            path="/pdfview/:proType/:userId/:dob"
            element={
              <PrivateRoute loginStatus={loginStatus}>
                <PDFScreen />
              </PrivateRoute>
            }
          />
          <Route path="/forget_PassWord" element={<ForgetPassword />} />
          <Route path="/" element={<Login setLoginStatus={setLoginStatus} />} />
           <Route path="*" element={<NotFoundPage />} /> 
        </Routes>
        <div className="md:ml-72 px-10  ">
          <Routes>
            <Route
              path="/dashboard"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <Dashboard />
                </PrivateRoute>
              }
            />

            <Route
              path="/notifications"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <Notifications />
                </PrivateRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <Profile />
                </PrivateRoute>
              }
            />

            <Route
              path="/profile/:adminId/:nId"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <Profile />
                </PrivateRoute>
              }
            />

            <Route
              path="/religions"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <Religions />
                </PrivateRoute>
              }
            />

            <Route
              path="/islam"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <Islam />
                </PrivateRoute>
              }
            />

            <Route
              path="/add_islam_profile"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <AddIslamProfile />
                </PrivateRoute>
              }
            />

            <Route
              path="/editProfile/:userId"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <EditProfile />
                </PrivateRoute>
              }
            />

            <Route
              path="/rmgeditProfile/:userId"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <RMGEditProfile />
                </PrivateRoute>
              }
            />

            <Route
              path="/ireditProfile/:userId"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <IREditProfile />
                </PrivateRoute>
              }
            />

            <Route
              path="/profile_details/:userId"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <ProfileDetails />
                </PrivateRoute>
              }
            />

            <Route
              path="/hindu"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <Hindhu />
                </PrivateRoute>
              }
            />

            <Route
              path="/add_hindu_profile"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <AddHunduProfile />
                </PrivateRoute>
              }
            />

            <Route
              path="/buddha"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <Buddha />
                </PrivateRoute>
              }
            />

            <Route
              path="/add_buddha_profile"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <AddBuddhaProfile />
                </PrivateRoute>
              }
            />

            <Route
              path="/cristan"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <Cristan />
                </PrivateRoute>
              }
            />

            <Route
              path="/add_cristan_profile"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <AddCristanProfile />
                </PrivateRoute>
              }
            />

            <Route
              path="/others_religion"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <OthersReligion />
                </PrivateRoute>
              }
            />

            <Route
              path="/add_others_religion_profile"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <AddOthersReligion />
                </PrivateRoute>
              }
            />

            <Route
              path="/all_religion"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <AllReligion />
                </PrivateRoute>
              }
            />

            <Route
              path="/rmg"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <RMG />
                </PrivateRoute>
              }
            />

            <Route
              path="/rmg_Profile_details/:userId"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <RMGProfileDetails />
                </PrivateRoute>
              }
            />

            <Route
              path="/garments_owners"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <GarmentsOwner />
                </PrivateRoute>
              }
            />

            <Route
              path="/add_garments_owners"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <AddGarmentsOwner />
                </PrivateRoute>
              }
            />
            <Route
              path="/grament_labour"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <GarmentsLabor />
                </PrivateRoute>
              }
            />

            <Route
              path="/add_grament_labour"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <AddgarmentsLabour />
                </PrivateRoute>
              }
            />

            <Route
              path="/ministry_profile"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <MinistryProfile />
                </PrivateRoute>
              }
            />

            <Route
              path="/add_ministry_profile"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <AddMinistryProfile />
                </PrivateRoute>
              }
            />

            <Route
              path="/others_rmg_profile"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <OthersRMG />
                </PrivateRoute>
              }
            />

            <Route
              path="/add_others_rmg_profile"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <AddOthersRmgProfile />
                </PrivateRoute>
              }
            />

            <Route
              path="/all_rmg_profile"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <AllRMG />
                </PrivateRoute>
              }
            />

            <Route
              path="/irprofiles"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <IrProfiles />
                </PrivateRoute>
              }
            />

            <Route
              path="/add_irprofiles"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <AddIrProfiles />
                </PrivateRoute>
              }
            />

            <Route
              path="/IR_profile_details/:userId"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <IrProfileDetails />
                </PrivateRoute>
              }
            />

            <Route
              path="/admins"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <Admins />
                </PrivateRoute>
              }
            />

            <Route
              path="/add_admins"
              element={
                <PrivateRoute loginStatus={loginStatus}>
                  <AddAdmin />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>

          <h3 className="footerContainer  p-3">Powered By <a style={{textDecoration:'none'}} href='https://wetechbd.com/'><span className="text-red-600 font-bold">WeTech</span></a> </h3>

          <div>
            <ToastContainer autoClose={3000} position={"top-center"} />
            {children}
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default App;
