import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import AdminProfileCard from "./AdminProfileCard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { getAdminApi, getDataApi } from "../../services/api.service";
import { toast } from "react-toastify";
import Loader from "../../Components/Animation/Loader";
import { deleteApi } from "./../../services/api.service";
const Admins = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");
  const [adminprofileData, setAdminProfileData] = useState([]);
  //console.log(adminprofileData, "adminprofileData");
  let adminId = localStorage.getItem("admin_id");
  const getDataApiCall = async () => {
    //console.log("heatin useeffet");
    const getdata = {};

    try {
      setIsLoading(true);
      // //console.log("try er moddhe");
      const data = await getAdminApi(`admin`);
      if (data.error === true) {
        toast.warning(data.message);
        return null;
      }
      setAdminProfileData(data?.data);
      // setPoliProfileData();
      setIsLoading(false);
    } catch (err) {
      //console.log(err);
    }
  };
  useEffect(() => {
    const callApi = async () => {
      await getDataApiCall();
    };
    callApi();
  }, []);
  const deleteProfile = async (id) => {
    const getdata = { myID: adminId };
    try {
      setIsLoading(true);
      //console.log("try er moddhe");
      const data = await getDataApi(`admin/${id}`, getdata);
      if (data.error === true) {
        toast.warning(data.message);
        return null;
      }
      //console.log("profile islam filter", data?.data);
      getDataApiCall();
      setIsLoading(false);
    } catch (err) {
      //console.log(err);
    }
  };
  if (isLoading === true) {
    return (
      <div className=" grid place-items-center xl:h-screen ">
        <Loader />
      </div>
    );
  }
  return (
    <div className=" pb-5">
      <h1 className=" mb-5 text-center text-2xl  xl:text-3xl">
        সকল এডমিন দের প্রোফাইল
      </h1>
      <div className="grid place-items-center ">
        <div>
          <Link to="/add_admins">
            <button className="commonbtn whitespace-nowrap text-left xl:text-center xl:py-2 overflow-hidden w-full">
              <PersonAddIcon className=" XL:mr-3" /> Add Profile
            </button>
          </Link>
        </div>
      </div>

      {adminprofileData?.length !== 0 ? (
        <div>
          <div>
            <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 my-5 gap-x-5 z-0">
              {adminprofileData?.map((prfiles) => (
                <AdminProfileCard
                  key={prfiles?.admin_id}
                  id={prfiles?.admin_id}
                  name={prfiles?.admin_name}
                  adminType={prfiles?.is_super_admin}
                  image={prfiles?.admin_img}
                  deleteProfile={deleteProfile}
                  admin_validation={prfiles?.admin_validation}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className=" grid place-items-center h-screen">
          <h1 className="  mb-5 text-center text-2xl  xl:text-3xl">
            কোনো প্রোফাইল তথ্য পাওয়া যায়নি
          </h1>
        </div>
      )}
    </div>
  );
};

export default Admins;
