import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
// import { adminLogin } from "./../../services/api.service";
import { Link, useNavigate } from "react-router-dom";
import BtnLoader from "../../Components/Animation/BtnLoader";
import { getDataApi } from "../../services/api.service";
import getAuth from "../../Utils/useRoute";
const Login = ({ setLoginStatus }) => {
  const expire = Math.floor(Date.now() / 1000);
  const expireTokenTime = localStorage.getItem("expire");
  //console.log(expireTokenTime >= expire ? true : false, "expiretime");

  const [value, setValue] = useState({
    admin_validation: 0,
    admin_pass: "",
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState("");
  let navigate = useNavigate();
  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (getAuth()) {
      navigate("/dashboard");
    }
  }, [getAuth]);
  // Somewhere in your code, e.g. inside a handler:

  // const handleValueChange = (event: any) => {
  //     setValue(event.target.value);
  // };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValue((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const [processing, setProcessing] = useState(false);
  const loginAdminApp = async () => {
    setProcessing(true);
    if (value.admin_validation.length > 0 && value.admin_pass.length > 0) {
      setEmptyMessage("");
    } else {
      setEmptyMessage("দয়া  করে সমস্ত ইনপুট ফিল্ড পূরণ করুন");
      setProcessing(false);
      return null;
    }
    const datakey = {
      admin_validation: value.admin_validation,
      admin_pass: value.admin_pass,
    };

    try {
      const data = await getDataApi(`admin/login`, datakey);
      if (data.error === true) {
        toast.warning(data.message);
        setProcessing(false);
        return null;
      }
      localStorage.setItem("adminToken", data?.data?.token);
      localStorage.setItem("expire", data?.data?.expire);
      localStorage.setItem("is_super_admin", data?.data?.is_super_admin);
      localStorage.setItem("admin_id", data?.data?.admin_id);
      //console.log("data from api ", data);
      setLoginStatus(true);
      toast.success("সফলভাবে লগইন হয়েছে ");
      setProcessing(false);
      navigate("/dashboard");
    } catch (err) {
      toast.warning(err);
    }
  };
  return (
    <div className=" grid place-items-center h-screen font-body">
      <div className="m-2  py-24 text-white border border-btncolor shadow-md rounded-md">
        <div className="mx-4">
          <h1 className="mb-12 text-base md:text-lg xl:text-2xl font-mono text-center">
            আপনার একাউন্টে সাইন ইন করুন
          </h1>

          <label>আপনার আইডি লিখুন</label>
          <input
            className="w-full py-3 px-2 my-4 bg-white text-base md:text-lg xl:text-xl text-black"
            type="number"
            min="0"
            onKeyPress={preventMinus}
            onPaste={preventPasteNegative}
            id="admin_validation"
            name="admin_validation"
            onChange={handleChange}
            placeholder="আইডি লিখুন"
          />

          <div>
            <label>আপনার পাসওয়ার্ড লিখুন</label>
            <input
              className="w-full py-3 px-2 my-4 bg-white  text-base md:text-lg xl:text-xl text-black"
              type={passwordShown ? "text" : "password"}
              id="admin_pass"
              name="admin_pass"
              onChange={handleChange}
              placeholder="পাসওয়ার্ড লিখুন"
            />
            <input
              id="recheckbox"
              type="checkbox"
              checked={passwordShown}
              onChange={(e) => {
                setPasswordShown(!passwordShown);
              }}
            />{" "}
            <label htmlFor="recheckbox">Show password?</label>
          </div>
        </div>
        {emptyMessage === "" ? null : (
          <span className=" mx-4 text-red-500 text-sm font-bold ">
            {value.admin_pass.length !== 0 &&
            value.admin_validation.length !== 0 ? (
              <></>
            ) : (
              <>{emptyMessage}</>
            )}
          </span>
        )}
        <div className="mx-4">
          {processing === true ? (
            <button
              type="button"
              className="w-full   my-4 mt-8 px-10 rounded-xl bg-purple-700   text-center"
              disabled
            >
              <span className=" flex justify-center items-center gap-x-6">
                {" "}
                <BtnLoader />
                প্রক্রিয়া করা হচ্ছে, অনুগ্রহ করে অপেক্ষা করুন
              </span>
              {/* <svg
                    className="animate-spin text-white spinner-border h-5 w-5 mr-3"
                    viewBox="0 0 24 24"
                  ></svg> */}
            </button>
          ) : (
            <button
              onClick={loginAdminApp}
              type="submit"
              className="w-full py-4 my-4 mt-8 px-10 rounded-xl bg-purple-600 hover:bg-purple-700 text-white text-center"
            >
              সাইন ইন করুন
            </button>
          )}

          <div className="grid place-items-end">
            <Link to="/forget_PassWord">
              <p className="cursor-pointer text-right text-lg p-3  hover:text-slate-300 hover:translate-y-1  duration-700 mt-5">
                {" "}
                পাসওয়ার্ড ভুলে গেছেন?{" "}
              </p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
