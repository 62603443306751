import React from 'react';
import { styles } from './Constant';

const Header = ({profile,proType}) => {
  const getProfileType = () => {
    if (proType === "religion") {
      return "ধর্মীয়";
    } else if (proType === "rmg") {
      return "আরএমজি";
    } else if (proType === "ir") {
      return "আন্তর্জাতিক সম্পর্ক";
    }
  };
  return (
    <div style={styles.header}>
        <div style={styles.detailColumn}>
         <p style={styles.name}> {profile.u_name} </p>
         <p style={styles.subtitle}>  {`${getProfileType()} প্রোফাইল`}  </p>
        </div>
      </div>
  )
}

export default Header