import React, { useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { BASE_URL } from "../../Utils/constants";
import axios from "axios";
import validator from "validator";
import { getDataApi } from "../../services/api.service";
import BtnLoader from "../../Components/Animation/BtnLoader";
import { imgErrorMsg } from "../../Constants/otherConstants";
import checkValidFile from "../../Utils/checkValidFile";
const AddAdmin = () => {
  const navigate = useNavigate();
  const [profileImg, setProfileImg] = useState({ file: "", link: "" });
  const [imageValidationStatus, setImgValidationStatus] = useState({
    proImg: true,
  });
  //console.log(imageValidationStatus);
  //console.log(profileImg.file, "profileImg file");
  const [adminValue, setAdminValue] = useState("no");
  const [admin_name, setadmin_name] = useState("");
  const [admin_validation, setadmin_validation] = useState("");
  const [value, setValue] = useState({
    // admin_name: "",
    // admin_validation: "",
    admin_pass: "",
    renewadmin_pass: "",
    admin_img: "",
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [emptyMessage, setEmptyMessage] = useState("");

  const [admin_passShown, setadmin_passShown] = useState(false);
  const [repasswordShown, setRePasswordShown] = useState(false);
  const [validMessage, setValidMessage] = useState(false);
  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      // setErrorMessage("");
      setValidMessage(false);
      // toast.warning("password valid");
    } else {
      // toast.warning("password is not valid");
      setValidMessage(true);
      // setErrorMessage(
      //   "পাসওয়ার্ডের মধ্যে একটি স্পেশাল সিম্বল(@#$..) এবং একটি বড় হাতের অক্ষর(A-Z),একটি ছোট হাতের অক্ষর(a-z), একটি সংখ্যা(0-9) থাকতে হবে"
      // );
    }
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    validate(value);
    setValue((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const handleAdminChange = (event) => {
    setAdminValue(event.target.value);
  };
  const onProfileImgChange = async (e) => {
    const file = e.target.files[0];
    ////console.log(checkValidFile(file))
    const validStatus = checkValidFile(file);
    setProcessing(true);
    setImgValidationStatus({ ...imageValidationStatus, proImg: validStatus });

    if (validStatus) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const AdminToken = localStorage.getItem("adminToken");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${AdminToken}`);

        const baseUrl = BASE_URL || "http://localhost:5005";
        const url = `${baseUrl}upload`;

        const res = await axios.post(url, formData, config);

        //console.log(res);
        if (res.data.success) {
          setProfileImg({
            ...profileImg,
            link: res.data.data[0].path,
            file: res.data.data[0].originalname,
          });
          toast.success("সফলভাবে ছবি আপলোড হয়েছে ");
          setProcessing(false);
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
  const [processing, setProcessing] = useState(false);
  const loginAdminApp = async () => {
    setProcessing(true);
    const values = value.admin_pass;
    //console.log(admin_name.length, "name");
    if (admin_name.length > 0 && admin_validation.length > 0) {
      setEmptyMessage("");
    } else {
      setEmptyMessage("দয়া  করে সমস্ত ইনপুট ফিল্ড  পূরণ করুন");
      setProcessing(false);
      return null;
    }
    if (
      value.admin_pass === value.renewadmin_pass &&
      value.admin_pass.length > 0 &&
      value.renewadmin_pass.length > 0 &&
      validator.isStrongPassword(values, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      // toast.success("পাসওয়ার্ড সফলভাবে পরিবর্তিত হয়েছে");
      setErrorMessage(false);
    } else {
      setErrorMessage(true);
      setProcessing(false);
      return null;
      // toast.success("");
    }
    const datakey = {
      admin_name: admin_name,
      admin_pass: value.admin_pass,
      admin_validation: admin_validation,
      admin_img: profileImg?.link,
      is_super_admin: adminValue,
    };
    //console.log(profileImg.link, "profileImg link");

    try {
      const data = await getDataApi(`admin`, datakey);
      if (data.error === true) {
        toast.warning(data.message);
        setProcessing(false);
        return null;
      }
      //console.log("data from api ", data);
      toast.success("সফলভাবে অ্যাডমিন প্রোফাইল তৈরী  হয়েছে ");
      setProcessing(false);
      navigate("/admins");
    } catch (err) {
      toast.warning(err);
    }
    // const url = "/auth/admin-login";
    // try {
    //   const data = await adminLogin(url, datakey);
    //   if (data.statusCode) {
    //     // //console.log('this block')
    //     toast.warning("Please Input a correct email and password");
    //     return;
    //   }
    //   // //console.log("dataforotp", data);
    //   toast.success("Successfully login");
    //   localStorage.setItem("adminToken", data.token);
    //   localStorage.setItem("adminId", data.admin.id);

    //   router.push("../dashboard");
    // } catch (err) {
    //   toast.warning("Please Input a correct email and password");
    // }

    // navigate("/admins");
  };
  return (
    <div>
      <div className=" border-2  border-btncolor shadow-md rounded-md font-body">
        <div className="xl:m-2 xl:p-2  text-white">
          <div className="xl:mx-4">
            <h1 className="mb-6 text-base md:text-lg xl:text-2xl font-mono text-center">
              একটি নতুন অ্যাডমিন প্রোফাইল যুক্ত করুন৷
            </h1>{" "}
            {/*/////////////////////////////////////////////// personal info-///////////////////////////////////////////////////////////// */}
            <div className=" mt-3">
              <h2 className=" text-base xl:text-xl m-2">ব্যক্তিগত তথ্য</h2>

              <div className=" shadow-lg rounded-lg p-3 grid grid-cols-1 xl:grid-cols-2 gap-x-5">
                <div>
                  <h2 className=" text-sm xl:text-lg mt-1">নাম</h2>
                  <input
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-md"
                    required
                    type="text"
                    id="admin_name"
                    name="admin_name"
                    // onChange={(e) => {
                    //   setadmin_name(e.target.value);
                    // }}
                    onChange={(e) => setadmin_name(e.target.value)}
                    placeholder="নাম লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg mt-1">
                    অ্যাডমিন বৈধতা নাম্বার{" "}
                  </h2>
                  <input
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-md"
                    required
                    type="text"
                    id="admin_validation"
                    name="admin_validation"
                    // onChange={handleChange}
                    onChange={(e) => setadmin_validation(e.target.value)}
                    placeholder="অ্যাডমিন বৈধতা  নাম্বার  লিখুন"
                  />
                </div>
                <div>
                  {" "}
                  <label>অ্যাডমিন পাসওয়ার্ড লিখুন</label>
                  <input
                    className="w-full py-3 px-2 my-2 bg-white text-base xl:text-xl text-black"
                    type={admin_passShown ? "text" : "password"}
                    id="admin_pass"
                    name="admin_pass"
                    onChange={handleChange}
                    placeholder="পাসওয়ার্ড লিখুন"
                  />
                  <input
                    id="newcheckbox"
                    type="checkbox"
                    checked={admin_passShown}
                    onChange={(e) => {
                      setadmin_passShown(!admin_passShown);
                    }}
                  />{" "}
                  <label htmlFor="newcheckbox">Show password?</label>
                  {/* {value.admin_pass.length === 0 ? (
                    <p>ইনপুট ফিল্ড খালি থাকা যাবে না</p>
                  ) : (
                    <p></p>
                  )} */}
                </div>
                <div>
                  <label>অ্যাডমিন পাসওয়ার্ড পুনরায় লিখুন</label>
                  <input
                    className="w-full py-3 px-2 my-2 bg-white text-base xl:text-xl text-black"
                    type={repasswordShown ? "text" : "password"}
                    id="renewadmin_pass"
                    name="renewadmin_pass"
                    onChange={handleChange}
                    placeholder="পাসওয়ার্ড লিখুন"
                  />
                  <input
                    id="recheckbox"
                    type="checkbox"
                    checked={repasswordShown}
                    onChange={(e) => {
                      setRePasswordShown(!repasswordShown);
                    }}
                  />{" "}
                  <label htmlFor="recheckbox">Show password?</label>
                </div>

                <div>
                  <h2 className=" text-sm xl:text-lg mt-1">এডমিন টাইপ </h2>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={adminValue}
                      onChange={handleAdminChange}
                    >
                      <div className="flex flex-row justify-between items-center">
                        <FormControlLabel
                          value="no"
                          control={<Radio color="secondary" />}
                          label="এডমিন"
                        />
                        <FormControlLabel
                          value="yes"
                          control={<Radio color="secondary" />}
                          label="সুপার এডমিন"
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
                {/* image */}
                <div className="my-3 xl:mt-10">
                  <label htmlFor="profileImg">
                    <input
                      style={{ display: "none" }}
                      id="profileImg"
                      name="profileImg"
                      onChange={onProfileImgChange}
                      type="file"
                    />
                    <Button
                      style={{
                        fontSize: "18px",
                        padding: "6px",
                        width: "100%",
                      }}
                      // className=" text-sm  xl:text-xl p-2 w-full"
                      color="secondary"
                      variant="contained"
                      component="span"
                    >
                      একটি প্রোফাইল ছবি আপলোড করুন
                    </Button>
                  </label>
                  <div>
                    {profileImg?.file ? (
                      <div> ফাইলের নাম : {profileImg?.file}</div>
                    ) : null}
                  </div>
                  {imageValidationStatus.proImg === false ? (
                    <p className=" text-red-600 text-xs font-semibold py-2">
                      {imgErrorMsg}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="m-4 text-justify">
              {/* <p className=" text-sm py-2">
                পাসওয়ার্ডের মধ্যে একটি স্পেশাল সিম্বল (@#$..) এবং একটি বড় হাতের
                অক্ষর (A-Z) , একটি ছোট হাতের অক্ষর (a-z) , একটি সংখ্যা (0-9) ও
                মিনিমাম ৮ অক্ষর থাকতে হবে
              </p> */}
              {emptyMessage === "" ? null : (
                <span className=" text-yellow-500 font-bold ">
                  {admin_name.length !== 0 && admin_validation.length !== 0 ? (
                    <></>
                  ) : (
                    <>{emptyMessage}</>
                  )}
                </span>
              )}
              {validMessage === true ? (
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  {/* {errorMessage} */}
                  <p className=" text-sm py-2">
                    পাসওয়ার্ডের মধ্যে একটি স্পেশাল সিম্বল (@#$..) এবং একটি বড়
                    হাতের অক্ষর (A-Z) , একটি ছোট হাতের অক্ষর (a-z) , একটি সংখ্যা
                    (0-9) ও মিনিমাম ৮ অক্ষর থাকতে হবে
                  </p>
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className="mx-4">
              {processing === true ? (
                <button
                  type="button"
                  className="w-full   my-4 mt-8 px-10 rounded-xl bg-purple-700   text-center"
                  disabled
                >
                  <span className=" flex justify-center items-center gap-x-6">
                    {" "}
                    <BtnLoader />
                    প্রক্রিয়া করা হচ্ছে, অনুগ্রহ করে অপেক্ষা করুন
                  </span>
                  {/* <svg
                    className="animate-spin text-white spinner-border h-5 w-5 mr-3"
                    viewBox="0 0 24 24"
                  ></svg> */}
                </button>
              ) : (
                <div>
                  {value.admin_pass === value.renewadmin_pass &&
                  validMessage === false ? (
                    <button
                      onClick={loginAdminApp}
                      // type="submit"
                      className="w-full py-4 my-4 mt-8 px-10 rounded-xl bg-purple-600 hover:bg-purple-700 text-white text-center"
                    >
                      জমা দিন
                    </button>
                  ) : (
                    <button
                      // onClick={changePassword}
                      // type="submit"
                      disabled
                      className=" cursor-not-allowed w-full py-4 my-4  px-10 rounded-xl bg-gray-400 text-white text-center"
                    >
                      জমা দিন
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdmin;
