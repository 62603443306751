import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import Loader from "../../Components/Animation/Loader";
import { getDataApi } from '../../services/api.service';
import { ComponentToPrint } from './ComponentToPrint ';

const PDFScreen = () => { 
  const componentRef = useRef();
  const params = useParams();
  const userid = params.userId;
  const proType = params.proType;
  const dob = params.dob;

  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState();
  //console.log(profileData)
  useEffect(() => {
    const getDataApiCall = async () => {
      const getdata = { profileType: proType };
      try {
        // //console.log("try er moddhe");
        setIsLoading(true);
        const data = await getDataApi(`profile/${userid}`, getdata);
        // //console.log("profile rmg", data);
        setProfileData(data?.data);
        //setProfileData({...profileData,u_dob:profileData.u_dob.slice(0,10)})
        //setProfileData({...profileData,proType:proType})
        setIsLoading(false);
      } catch (err) {
        //console.log(err);
      }
    };
    const callApi = async () => {
      await getDataApiCall();
    };
    callApi();
  }, []);

  return (
    <>
    {profileData ? (
      <div style={{
        width: "100%",
        margin: "0 auto ",
        "@media (max-width: 768px)": {
          width: "100% ",
          margin: "0 auto ",
        },
      }
      }>
      <ReactToPrint
      documentTitle={profileData.u_name}
        trigger={() => <button className="commonbtn my-3 whitespace-nowrap text-left xl:text-center xl:py-2 overflow-hidden d-block">
        <PictureAsPdfIcon className=" xl:mr-3" />  ডাউনলোড 
      </button>}
        content={() => componentRef.current}
      />
      <ComponentToPrint ref={componentRef} profile={profileData} proType={proType} dob={dob} />
    </div>
    ):
    (
      <div style={{display:"flex",justifyContent:'center',alignItems:'center'}}>
      <Loader/>
      </div>
    )
  }
    </>

  );
}

export default PDFScreen