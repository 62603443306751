import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../Components/Animation/Loader";
import { getAdminApi } from "./../../services/api.service";
const RMG = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //console.log(dashboardData, "dashboardData");

  const getDataApiCall = async () => {
    //console.log("heatin useeffet");
    const getdata = {};

    try {
      setIsLoading(true);
      // //console.log("try er moddhe");
      const data = await getAdminApi(`profile/dashboard`);
      if (data.error === true) {
        toast.warning(data.message);
        return null;
      }
      setDashboardData(data?.data);
      // setPoliProfileData();
      setIsLoading(false);
    } catch (err) {
      //console.log(err);
    }
  };
  useEffect(() => {
    const callApi = async () => {
      await getDataApiCall();
    };
    callApi();
  }, []);
  if (isLoading === true) {
    return (
      <div className=" grid place-items-center xl:h-screen ">
        <Loader />
      </div>
    );
  }
  return (
    <div className="  ">
      <div className=" grid grid-cols-1 md:grid-cols-2  gap-10 pb-10">
        <Link to="/garments_owners">
          <div className="ownerbg rounded-xl ">
            <div className="animate__animated  animate__fadeInUp     h-72 flex flex-col gap-5 justify-center items-center  hover:translate-y-1 duration-1000  cursor-pointer shadow-xl border-2 border-slate-100 p-10 text-center rounded-xl">
              <h1 className=" text-center text-lg xl:text-2xl">
                গার্মেন্টস মালিক প্রোফাইল
              </h1>
              <h1 className=" text-center text-lg xl:text-xl">
                প্রোফাইল সংখ্যা : {dashboardData?.totalOwners} টি{" "}
              </h1>
              {/* <button className="  text-sm xl:text-lg whitespace-nowrap commonbtn">
              View Details
            </button> */}
            </div>
          </div>
        </Link>
        <Link to="/grament_labour">
          <div className="laburbg rounded-xl ">
            {" "}
            <div className="animate__animated animate__fadeInUp  h-72 flex flex-col gap-5 justify-center items-center hover:translate-y-1 duration-1000  cursor-pointer shadow-xl border-2 border-slate-100 p-10 text-center rounded-xl">
              <h1 className=" text-center  text-lg xl:text-2xl ">
                গার্মেন্টস লেবার প্রোফাইল
              </h1>
              <h1 className=" text-center  text-lg xl:text-xl">
                প্রোফাইল সংখ্যা : {dashboardData?.totalLabours} টি{" "}
              </h1>
            </div>
          </div>
        </Link>
        <Link to="/ministry_profile">
          <div className="ministrybg rounded-xl ">
            <div className="animate__animated animate__fadeInDown  h-72 flex flex-col gap-5 justify-center items-center hover:translate-y-1 duration-1000  cursor-pointer shadow-xl border-2 border-slate-100 p-10 text-center rounded-xl">
              <h1 className=" text-center  text-lg xl:text-2xl ">
                মন্ত্রণালয়ের প্রোফাইল
              </h1>
              <h1 className=" text-center  text-lg xl:text-xl">
                প্রোফাইল সংখ্যা :{dashboardData?.totalMinistries} টি{" "}
              </h1>
            </div>
          </div>
        </Link>
        <Link to="/others_rmg_profile">
          <div className="othersRelBg rounded-xl ">
            <div className=" animate__animated animate__fadeInDown  h-72 flex flex-col gap-5 justify-center items-cente rhover:translate-y-1 duration-1000  cursor-pointer shadow-xl border-2 border-slate-100 p-10 text-center rounded-xl">
              <h1 className=" text-lg xl:text-2xl">অন্যান্য</h1>
              <h1 className=" text-center  text-lg xl:text-xl">
                প্রোফাইল সংখ্যা : {dashboardData?.totalOtherRmg} টি{" "}
              </h1>
            </div>
          </div>
        </Link>
        <Link to="/all_rmg_profile">
          <div className="allRelBg rounded-xl overflow-hidden ">
            <div className=" animate__animated animate__fadeInRight  h-72  flex flex-col gap-5 justify-center items-center hover:translate-y-1 duration-500  cursor-pointer shadow-xl border-2 border-slate-100 p-10 text-center rounded-xl">
              <h1 className=" text-lg xl:text-2xl">সকল আরএমজি এর প্রোফাইল</h1>
              <h1 className=" text-center  text-lg xl:text-2xl">
                প্রোফাইল সংখ্যা : {dashboardData?.totalRmgProfiles} টি{" "}
              </h1>
              <button className=" py-2 text-sm md:text-base xl:text-lg whitespace-nowrap xl:py-4  px-4 xl:px-10 rounded-xl bg-purple-600 hover:bg-purple-700 text-white text-center">
                একত্রে দেখতে ক্লিক করুন
              </button>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default RMG;
