import React from 'react'
import { styles } from './Constant'

const PersonalInfo = ({profile,dob}) => {
  return (
    <div style={styles.innerContainer}>
    <div style={styles.sectionContainer}>
      <h3 style={styles.subHeader}>  ব্যক্তিগত তথ্য </h3>

      <div style={styles.item}>
      <h4 style={styles.tag}> নাম </h4>
      <h5 style={styles.value}> {profile.u_name} </h5>
      </div>     
      
      
<div style={styles.item}>
<h4 style={styles.tag}> পিতার নামঃ </h4>
<h4 style={styles.value} >{profile.u_father_name}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> ধর্মঃ </h4>
<h4 style={styles.value}>{profile.u_religion}   </h4>
</div>


<div style={styles.item}>
<h4 style={styles.tag}> জন্ম তারিখ: </h4>
<h4 style={styles.value}>{dob}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> পেশা: </h4>
<h4 style={styles.value} >{profile.u_profession}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> শিক্ষাগত যোগ্যতা: </h4>
<h4 style={styles.value}>{profile.u_education}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> জাতীয়তা: </h4>
<h4 style={styles.value}>{profile.u_nationality}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> লিঙ্গ: </h4>
<h4 style={styles.value}>{profile.u_gender}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}>  বৈবাহিক অবস্থা: </h4>
<h4 style={styles.value}>{profile.u_married_status}   </h4>
</div>

{profile.u_married_status ==="বিবাহিত" && (
<div style={styles.item}>
<h4 style={styles.tag}> ছেলেমেয়ে সংখ্যা: </h4>
<h4 style={styles.value}>{profile.u_no_of_children} জন   </h4>
</div>
)}



<div style={styles.item}>
<h4 style={styles.tag}> মোবাইল নং-১: </h4>
<h4 style={styles.value}>{`0${profile.u_phone1}`}   </h4>
</div> 


<div style={styles.item}>
<h4 style={styles.tag}> মোবাইল নং-২: </h4>
<h4 style={styles.value}>{`0${profile.u_phone2}`}   </h4>
</div>


</div>


<div style={styles.sectionContainer} break>
<h4 style={styles.subHeader}> ঠিকানা </h4> 

<div style={styles.item}>
<h4 style={styles.tag}> বর্তমান বিভাগঃ </h4>
<h4 style={styles.value}>{profile.u_t_division}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> বর্তমান জেলাঃ </h4>
<h4 style={styles.value}>{profile.u_t_district}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> বর্তমান উপজেলা/থানাঃ  </h4>
<h4 style={styles.value}>{profile.u_t_upozilla}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> ডাকঘরঃ  </h4>
<h4 style={styles.value}>{profile.u_t_post_code}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> বিস্তারিত বর্তমান ঠিকানা </h4>
<h4 style={styles.value}>{profile.u_t_others}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> স্থায়ী বিভাগঃ </h4>
<h4 style={styles.value}>{profile.u_p_division}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> স্থায়ী জেলাঃ </h4>
<h4 style={styles.value}>{profile.u_p_district}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> স্থায়ী উপজেলা/থানাঃ  </h4>
<h4 style={styles.value}>{profile.u_p_upozilla}   </h4>
</div>


<div style={styles.item}>
<h4 style={styles.tag}> ডাকঘরঃ  </h4>
<h4 style={styles.value}>{profile.u_p_post_code}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> বিস্তারিত স্থায়ী ঠিকানা </h4>
<h4 style={styles.value}>{profile.u_p_others}   </h4>
</div>

</div>

<div style={styles.sectionContainer}>
<h4 style={styles.subHeader}> আইডেন্টিফিকেশন নম্বর </h4> 

<div style={styles.item}>
<h4 style={styles.tag}> জাতীয় পরিচয়পত্র: </h4>
<h4 style={styles.value}>{profile.u_nid}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> পাসপোর্ট: </h4>
<h4 style={styles.value}>{profile.u_passport}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> ড্রাইভিং লাইসেন্স: </h4>
<h4 style={styles.value}>{profile.u_d_license}   </h4>
</div>

<div style={styles.item}>
<h4 style={styles.tag}> টিআইএন: </h4>
<h4 style={styles.value}>{profile.u_tin}   </h4>
</div>
      
    </div>
   </div>
  )
}

export default PersonalInfo