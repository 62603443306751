import { BASE_URL } from "./../Utils/constants";

export const imageUploadApi = async (endpoint, options = {}) => {
  // //console.log("endpoint holo", endpoint);
  //console.log("options er data", options);
  const AdminToken = localStorage.getItem("adminToken");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AdminToken}`);
  const formdata = new FormData();
  formdata.append("image", options);
  // formdata.append("image", options.u_nid_img);
  // formdata.append("image", options.u_passport_img);
  // formdata.append("image", options.u_d_license_img);
  // formdata.append("image", options.u_tin_img);

  const baseUrl = BASE_URL || "http://localhost:5005";
  const url = `${baseUrl}${endpoint}`;
  //console.log(url, "url");

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: formdata,
  });

  return response.json();
};
export const getDataApi = async (endpoint, options = {}) => {
  // //console.log("endpoint holo", endpoint);
  //console.log("options er data", options);
  const AdminToken = localStorage.getItem("adminToken");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AdminToken}`);
  myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify(options);
  // //console.log(body,"bodyyyy");
  // //console.log(myHeaders, "myHeaders token");

  const baseUrl = BASE_URL || "http://localhost:5005";
  const url = `${baseUrl}${endpoint}`;
  //console.log(url, "url");

  const response = await fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: raw,
  });

  return response.json();
};
export const getAdminApi = async (endpoint, options = {}) => {
  // //console.log("endpoint holo", endpoint);
  // //console.log("options er data", options);
  const AdminToken = localStorage.getItem("adminToken");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AdminToken}`);
  // myHeaders.append("Content-Type", "application/json");
  const raw = "";
  // //console.log(body,"bodyyyy");
  // //console.log(myHeaders, "myHeaders token");

  const baseUrl = BASE_URL || "http://localhost:5005";
  const url = `${baseUrl}${endpoint}`;
  //console.log(url, "url");

  const response = await fetch(url, {
    method: "GET",
    headers: myHeaders,
    // body: raw,
  });

  return response.json();
};
export const putApi = async (endpoint, options = {}) => {
  // //console.log("endpoint holo", endpoint);
  //console.log("options er data", options);
  const AdminToken = localStorage.getItem("adminToken");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AdminToken}`);
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify(options);
  // //console.log(body,"bodyyyy");
  // //console.log(myHeaders, "myHeaders token");

  const baseUrl = BASE_URL || "http://localhost:5005";
  const url = `${baseUrl}${endpoint}`;
  //console.log(url, "url");

  const response = await fetch(url, {
    method: "PUT",
    headers: myHeaders,
    body: raw,
  });

  return response.json();
};
export const deleteApi = async (endpoint, options = {}) => {
  // //console.log("endpoint holo", endpoint);
  //console.log("options er data", options);
  const AdminToken = localStorage.getItem("adminToken");
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${AdminToken}`);
  myHeaders.append("Content-Type", "application/json");
  const raw = JSON.stringify(options);
  // //console.log(body,"bodyyyy");
  // //console.log(myHeaders, "myHeaders token");

  const baseUrl = BASE_URL || "http://localhost:5005";
  const url = `${baseUrl}${endpoint}`;
  //console.log(url, "url");

  const response = await fetch(url, {
    method: "DELETE",
    headers: myHeaders,
    body: raw,
  });

  return response.json();
};
