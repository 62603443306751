import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import userImg from "../../Assets/Image/userimg.png";
import { getDataApi } from "../../services/api.service";
import Loader from "../Animation/Loader";
import { IMAGE_BASE_URL } from "./../../Utils/constants";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //   border: `1px solid ${theme.palette.divider}`,
  //   "&:not(:last-child)": {
  //     borderBottom: 0,
  //   },
  //   "&:before": {
  //     display: "none",
  //   },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "1.5rem", color: "white" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgb(71 85 105 )" : "#0a99c0",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  //   borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const RMGProfileDetails = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState("");
  //console.log(profileData, "profileData single ");
  const userid = params.userId;
  const [expanded, setExpanded] = useState("panel1");
  useEffect(() => {
    //console.log("heat");
    const getDataApiCall = async () => {
      const getdata = { profileType: "rmg" };
      try {
        // //console.log("try er moddhe");
        setIsLoading(true);
        const data = await getDataApi(`profile/${userid}`, getdata);
        //console.log("profile rmg", data);
        setProfileData(data);
        setIsLoading(false);
      } catch (err) {
        //console.log(err);
      }
    };
    const callApi = async () => {
      await getDataApiCall();
    };
    callApi();
  }, []);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const profileInfo = profileData?.data;
  if (isLoading === true) {
    return (
      <div className=" grid place-items-center xl:h-screen ">
        <Loader />
      </div>
    );
  }
  return (
    <div className="  border-2  border-btncolor shadow-md  rounded-2xl pt-10 relative ">
      {profileData.success ? (
        <div className="  ">
          <div className=" grid place-items-center  ">
            {profileInfo?.u_pro_img ? (
              <img
                className=" w-28 h-28 z-20  bg-imgcolor rounded-full"
                src={`${IMAGE_BASE_URL}/${profileInfo?.u_pro_img}`}
                alt="profileimg"
              />
            ) : (
              <img
                className=" w-24 h-24 z-20 rounded-full"
                src={userImg}
                alt="profileimg"
              />
            )}
            <p className=" text-lg xl:text-2xl py-5">{profileInfo?.u_name}</p>
          </div>
          <div className=" ">
            <div>
              <div className="flex flex-col sm:flex-row justify-between items-center overflow-hidden xl:-mt-20">
                <div className=" grid place-items-start p-1 ">
                  <Link
                    to={`/pdfview/rmg/${userid}/${profileInfo?.u_dob.slice(0,10)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="commonbtn my-3 whitespace-nowrap text-left xl:text-center xl:py-2 overflow-hidden">
                      <PictureAsPdfIcon className=" xl:mr-3" /> পিডিএফ
                    </button>
                  </Link>
                </div>

                <div className=" grid place-items-end p-1 ">
                  <Link to={`/rmgeditProfile/${userid}`}>
                    <button className="commonbtn whitespace-nowrap text-left xl:text-center xl:py-2 overflow-hidden">
                      <BorderColorOutlinedIcon className=" xl:mr-3" /> প্রোফাইল
                      এডিট করুন
                    </button>
                  </Link>
                </div>
              </div>

              {/* personal info */}
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                className=""
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  className=""
                >
                  <Typography className=" text-white text-2xl">
                    ব্যক্তিগত তথ্য
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className=" bg-purple-800 text-gray-50">
                  <div>
                    <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        পিতার নাম {"=> "} {profileInfo?.u_father_name}
                      </h1>
                      {/* <h1 className=" p-2 border-[0.1px] border-slate-500  hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        মাতার নাম {"=> "} {profileInfo?.u_mother_name}
                      </h1> */}
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        জন্ম তারিখ {"=> "} {profileInfo?.u_dob.slice(0, 10)}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        ধর্ম {"=> "} {profileInfo?.u_religion}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        প্রোফাইলের ধরন {"=> "} {profileInfo?.u_type}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        পেশা {"=> "} {profileInfo?.u_profession}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        শিক্ষাগত যোগ্যতা {"=> "} {profileInfo?.u_education}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        জাতীয়তা {"=> "}
                        {profileInfo?.u_nationality}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        লিঙ্গ {"=> "} {profileInfo?.u_gender}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        বৈবাহিক অবস্থা {"=> "} {profileInfo?.u_married_status}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        ছেলেমেয়ে সংখ্যা {"=> "} {profileInfo?.u_no_of_children}
                      </h1>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              {/* Address */}
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <Typography className=" text-white text-2xl">
                    ঠিকানা
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className=" bg-purple-800 text-gray-50">
                  <div>
                    <h1 className=" text-lg xl:text-xl  pb-2">
                      বর্তমান ঠিকানা
                    </h1>
                    <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        বিভাগ {"=> "} {profileInfo?.u_t_division}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500  hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        জেলা{"=> "} {profileInfo?.u_t_district}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        উপজেলা/ থানা {"=> "} {profileInfo?.u_t_upozilla}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        ডাক ঘর
                        {"=> "} {profileInfo?.u_t_post_code}
                      </h1>
                      <h1 className="xl:col-span-2 p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        অন্যান্য ঠিকানা লিখুন {"=> "}
                        {profileInfo?.u_t_others}
                      </h1>
                      <h1 className=" md:col-span-2 xl:col-span-3 text-lg xl:text-xl  pb-2">
                        স্থায়ী ঠিকানা
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        বিভাগ {"=> "} {profileInfo?.u_p_division}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500  hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        জেলা{"=> "} {profileInfo?.u_p_district}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        উপজেলা/ থানা {"=> "} {profileInfo?.u_p_upozilla}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        ডাক ঘর
                        {"=> "} {profileInfo?.u_p_post_code}
                      </h1>
                      <h1 className=" xl:col-span-2 p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        অন্যান্য ঠিকানা লিখুন {"=> "} {profileInfo?.u_p_others}
                      </h1>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              {/* identifications */}
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <Typography className=" text-white text-2xl">
                    আইডেন্টিফিকেশন নম্বর
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className=" bg-purple-800 text-gray-50">
                  <div>
                    {/* <h1 className=" text-lg xl:text-xl  pb-2">বর্তমান ঠিকানা</h1> */}
                    <div className=" grid grid-cols-1 xl:grid-cols-2  gap-x-3 gap-y-8 md:gap-y-5">
                      <div className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl overflow-y-auto ">
                        <h1 className="text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500  pb-3">
                          জাতীয় পরিচয়পত্র {"=> "} {profileInfo?.u_nid}
                        </h1>
                        {profileInfo?.u_nid_img ? (
                          <img
                            className="w-full h-full md:h-60 md:w-5/6"
                            src={`${IMAGE_BASE_URL}/${profileInfo?.u_nid_img}`}
                            alt="nidimg"
                          />
                        ) : null}
                      </div>
                      <div className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl  overflow-y-auto    ">
                        <h1 className="text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500  pb-3">
                          পাসপোর্ট নম্বর {"=> "} {profileInfo?.u_passport}
                        </h1>
                        {profileInfo?.u_passport_img ? (
                          <img
                            className="w-full h-full md:h-60 md:w-5/6"
                            src={`${IMAGE_BASE_URL}/${profileInfo?.u_passport_img}`}
                            alt="nidimg"
                          />
                        ) : null}
                      </div>
                      <div className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl  overflow-y-auto   ">
                        <h1 className="text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500  pb-3">
                          ড্রাইভিং লাইসেন্স নম্বর {"=> "}{" "}
                          {profileInfo?.u_d_license}
                        </h1>
                        {profileInfo?.u_d_license_img ? (
                          <img
                            className=" w-full h-full md:h-60 md:w-5/6"
                            src={`${IMAGE_BASE_URL}/${profileInfo?.u_d_license_img}`}
                            alt="nidimg"
                          />
                        ) : null}
                      </div>
                      <div className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl  overflow-y-auto   ">
                        <h1 className="text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500  pb-3">
                          টিআইএন নম্বর {"=> "} {profileInfo?.u_tin}
                        </h1>
                        {profileInfo?.u_tin_img ? (
                          <img
                            className=" w-full h-full md:h-60 md:w-5/6"
                            src={`${IMAGE_BASE_URL}/${profileInfo?.u_tin_img}`}
                            alt="nidimg"
                          />
                        ) : null}
                      </div>

                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        {"=> "} {profileInfo?.u_tin}
                      </h1>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              {/* mobile numbers */}
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  aria-controls="panel4d-content"
                  id="panel4d-header"
                >
                  <Typography className=" text-white text-2xl">
                    যোগাযোগ মাধ্যম
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className=" bg-purple-800 text-gray-50">
                  <div>
                    <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        প্রথম মোবাইল নম্বর {"=> "} 0{profileInfo?.u_phone1}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500  hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        দ্বিতীয় মোবাইল নম্বর
                        {"=> "} 0{profileInfo?.u_phone2}
                      </h1>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              {/* Assets */}
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  aria-controls="panel5d-content"
                  id="panel5d-header"
                >
                  <Typography className=" text-white text-2xl">
                    সম্পদ এর বিবরণ
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className=" bg-purple-800 text-gray-50">
                  <div>
                    <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        স্থাবর সম্পদ
                        {"=> "} {profileInfo?.u_sthabor_property}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500  hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        অস্থাবর সম্পদ
                        {"=> "} {profileInfo?.u_osthabor_property}
                      </h1>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              {/* songothon */}
              <Accordion
                expanded={expanded === "panel6"}
                onChange={handleChange("panel6")}
              >
                <AccordionSummary
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                >
                  <Typography className=" text-white text-2xl">
                    সংগঠন সংক্রান্ত
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className=" bg-purple-800 text-gray-50">
                  <div>
                    <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
                      {profileInfo?.organizations.length === 0 ? (
                        <h1 className="col-span-1  md:col-span-2 xl:col-span-3 p-2 border-[0.1px] border-slate-500  hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   hover:p-1 hover:rounded-xl  duration-1000 hover:bg-sky-500   ">
                          সংগঠন
                          {"=> "} জড়িত নয়
                        </h1>
                      ) : (
                        <h1 className="col-span-1 md:col-span-2 p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                          সংগঠন সমূহ
                          {"=> "}{" "}
                          {profileInfo?.organizations?.map((islamicorg) => (
                            <span key={islamicorg.org_u_id} className="">
                              {islamicorg.org_name} ,
                            </span>
                          ))}
                        </h1>
                      )}

                      {/* <h1 className=" p-2 border-[0.1px] border-slate-500  hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500   ">
                        অন্যান্য
                        {"=> "} {profileInfo?.u_others_org}
                      </h1> */}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              {/* others info */}
              <Accordion
                expanded={expanded === "panel7"}
                onChange={handleChange("panel7")}
              >
                <AccordionSummary
                  aria-controls="panel7d-content"
                  id="panel7d-header"
                >
                  <Typography className=" text-white text-2xl">
                    অন্যান্য তথ্য
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className=" bg-purple-800 text-gray-50">
                  <div>
                    <div className=" grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        বার্ষিক আয় এবং আয়ের উৎস {"=> "}{" "}
                        {profileInfo?.u_anual_income}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        আয়কর সংক্রান্ত তথ্য {"=> "} {profileInfo?.u_tax}
                      </h1>

                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        বিদেশ ভ্রমণ সংক্রান্ত {"=> "} {profileInfo?.u_travel}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        মামলা সংক্রান্ত {"=> "} {profileInfo?.u_mamla}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        গাড়ি সংক্রান্ত {"=> "} {profileInfo?.u_car}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        রাজনৈতিক সংশ্লিষ্টতার বিস্তারিত বিবরণ {"=> "}{" "}
                        {profileInfo?.u_political}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        নির্বাচনে অংশগ্রহণ সংক্রান্ত তথ্য {"=> "}{" "}
                        {profileInfo?.u_election}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        সংগঠনের উপর প্রভাব {"=> "}{" "}
                        {profileInfo?.u_influence_org}
                      </h1>
                      {profileInfo?.u_tablig === null ? null : (
                        <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                          তাবলীগ জামাত সংক্রান্ত তথ্য {"=> "}{" "}
                          {profileInfo?.u_tablig}
                        </h1>
                      )}
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        মামলা সংক্রান্ত তথ্য {"=> "} {profileInfo?.u_mamla}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        ঘনিষ্ঠ মিত্রদের নাম {"=> "} {profileInfo?.u_frnds}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        বিরোধী ব্যক্তিদের নাম {"=> "} {profileInfo?.u_enemy}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500      ">
                        বহির্বিশ্ব এর সাথে যোগাযোগ {"=> "}{" "}
                        {profileInfo?.u_connectivity}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        নেতিবাচক বক্তব্য ও কর্মকান্ড {"=> "}{" "}
                        {profileInfo?.u_negatives}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        স্থানীয় প্রভাব ও প্রতিপত্তি সংক্রান্ত তথ্যাদি
                        {"=> "} {profileInfo?.u_local_influence}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        দুর্নীতি, চরমপন্থী, জঙ্গিবাদ ও নাশকতামূলক সংক্রান্ত
                        তথ্যাদি {"=> "} {profileInfo?.u_corruption}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        পারিবারিক তথ্যাদি {"=> "} {profileInfo?.u_family_info}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        অন্যান্য তথ্যাদি {"=> "} {profileInfo?.u_others}
                      </h1>
                      <h1 className=" p-2 border-[0.1px] border-slate-500 hover:shadow-md rounded-xl text-base  break-words overflow-auto scrollbar-hide   whitespace-pre-wrap hover:text-lg   duration-1000 hover:bg-sky-500    ">
                        মন্তব্য {"=> "} {profileInfo?.u_comment}
                      </h1>
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default RMGProfileDetails;
