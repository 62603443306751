import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Religions.css";
import { toast } from "react-toastify";
import Loader from "../../Components/Animation/Loader";
import { getAdminApi } from "./../../services/api.service";
const Religions = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //console.log(dashboardData, "dashboardData");

  const getDataApiCall = async () => {
    //console.log("heatin useeffet");
    const getdata = {};

    try {
      setIsLoading(true);
      // //console.log("try er moddhe");
      const data = await getAdminApi(`profile/dashboard`);
      if (data.error === true) {
        toast.warning(data.message);
        return null;
      }
      setDashboardData(data?.data);
      // setPoliProfileData();
      setIsLoading(false);
    } catch (err) {
      //console.log(err);
    }
  };
  useEffect(() => {
    const callApi = async () => {
      await getDataApiCall();
    };
    callApi();
  }, []);
  if (isLoading === true) {
    return (
      <div className=" grid place-items-center xl:h-screen ">
        <Loader />
      </div>
    );
  }
  return (
    <div className=" w-full h-full ">
      <div className=" grid grid-cols-1 md:grid-cols-2 gap-10 pb-10">
        <Link to="/islam">
          <div className="islambg rounded-xl">
            {" "}
            <div className="animate__animated animate__fadeInTopLeft  h-72 flex flex-col gap-5 justify-center items-center  hover:translate-y-1 duration-500  cursor-pointer shadow-xl border-2 border-slate-100 p-10 text-center rounded-xl">
              <h1 className=" text-center  text-xl xl:text-3xl">ইসলাম ধর্ম</h1>
              <h1 className=" text-center  text-lg xl:text-2xl">
                প্রোফাইল সংখ্যা : {dashboardData?.totalIslams} টি{" "}
              </h1>
            </div>
          </div>
        </Link>
        <Link to="/hindu">
          <div className="hindhubg rounded-xl">
            <div className=" animate__animated animate__fadeInTopRight  h-72 flex flex-col gap-5 justify-center items-center  hover:translate-y-1 duration-500  cursor-pointer shadow-xl border-2 border-slate-100 p-10 text-center rounded-xl">
              <h1 className=" text-lg xl:text-2xl">হিন্দু ধর্ম</h1>
              <h1 className=" text-center  text-lg xl:text-2xl">
                প্রোফাইল সংখ্যা : {dashboardData?.totalHindus} টি{" "}
              </h1>
            </div>
          </div>
        </Link>
        <Link to="/buddha">
          <div className="buddhabg rounded-xl">
            <div className=" animate__animated animate__fadeInBottomLeft  h-72 flex flex-col gap-5 justify-center items-center hover:translate-y-1 duration-500  cursor-pointer shadow-xl border-2 border-slate-100 p-10 text-center rounded-xl">
              <h1 className=" text-lg xl:text-2xl">বৌদ্ধ ধর্ম</h1>
              <h1 className=" text-center  text-lg xl:text-2xl">
                প্রোফাইল সংখ্যা : {dashboardData?.totalBuddhas} টি{" "}
              </h1>
            </div>
          </div>
        </Link>
        <Link to="/cristan">
          <div className="cristanbg rounded-xl">
            <div className=" animate__animated animate__fadeInBottomRight  h-72 flex flex-col gap-5 justify-center items-center hover:translate-y-1 duration-500  cursor-pointer shadow-xl border-2 border-slate-100 p-10 text-center rounded-xl">
              <h1 className=" text-lg xl:text-2xl">খ্রিস্টান ধর্ম</h1>
              <h1 className=" text-center  text-lg xl:text-2xl">
                প্রোফাইল সংখ্যা : {dashboardData?.totalChristians} টি{" "}
              </h1>
            </div>
          </div>
        </Link>
        <Link to="/others_religion">
          <div className="othersRelBg rounded-xl">
            <div className=" animate__animated animate__fadeInLeft  h-72  flex flex-col gap-5 justify-center items-center hover:translate-y-1 duration-500  cursor-pointer shadow-xl border-2 border-slate-100 p-10 text-center rounded-xl">
              <h1 className=" text-lg xl:text-2xl">অন্যান্য ধর্মসমূহ</h1>
              <h1 className=" text-center  text-lg xl:text-2xl">
                প্রোফাইল সংখ্যা : {dashboardData?.totalOtherReligions}{" "}
              </h1>
            </div>
          </div>
        </Link>
        <Link to="/all_religion">
          <div className="allRelBg rounded-xl overflow-hidden ">
            <div className=" animate__animated animate__fadeInRight  h-72  flex flex-col gap-5 justify-center items-center hover:translate-y-1 duration-500  cursor-pointer shadow-xl border-2 border-slate-100 p-10 text-center rounded-xl">
              <h1 className=" text-lg xl:text-2xl">সকল ধর্ম এর প্রোফাইল</h1>
              <h1 className=" text-center  text-lg xl:text-2xl">
                প্রোফাইল সংখ্যা : {dashboardData?.totalRelProfiles}টি{" "}
              </h1>
              <button className=" py-2 text-sm md:text-base xl:text-lg whitespace-nowrap xl:py-4  px-4 xl:px-10 rounded-xl bg-purple-600 hover:bg-purple-700 text-white text-center">
                একত্রে দেখতে ক্লিক করুন
              </button>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Religions;
