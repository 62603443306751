import React, { useState } from "react";
import { toast } from "react-toastify";
// import { adminLogin } from "./../../services/api.service";
import { Link, useNavigate } from "react-router-dom";
import { getDataApi } from "../../services/api.service";
import BtnLoader from "../../Components/Animation/BtnLoader";
const ForgetPassword = () => {
  let navigate = useNavigate();
  const [validId, setValidId] = useState(0);
  const [emptyMessage, setEmptyMessage] = useState("");

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };
  const [processing, setProcessing] = useState(false);
  const ForgetPassword = async () => {
    setProcessing(true);
    let date = new Date().toJSON();
    //console.log(date);
    if (validId.length > 0) {
      setEmptyMessage("");
    } else {
      setEmptyMessage("দয়া  করে আইডি ফিল্ড পূরণ করুন");
      setProcessing(false);
      return null;
    }
    const datakey = {
      senderValidationId: validId,
      time: date,
      readStatus: "no",
      updateStatus: "no",
    };

    try {
      const data = await getDataApi(`admin/noti`, datakey);
      if (data.error === true) {
        toast.warning(data.message);
        setProcessing(false);
        return null;
      }

      //console.log("data from api ", data);
      toast.info("আপনার পাসওয়ার্ডের জন্য সুপার এডমিনের সাথে যোগাযোগ করুন");
      setProcessing(false);
      navigate("/");
    } catch (err) {
      toast.warning(err);
    }
  };
  return (
    <div className=" grid place-items-center h-screen font-body">
      <div className="m-2  py-24 text-white border border-btncolor shadow-md rounded-md">
        <div className="mx-4">
          <h1 className="mb-12 text-base md:text-lg xl:text-2xl font-mono text-center">
            অ্যাকাউন্ট পুনরুদ্ধার
          </h1>

          <label>আপনার আইডি লিখুন</label>
          <input
            className="w-full py-3 px-2 my-2 bg-white text-sm md:text-lg xl:text-xl text-black"
            type="number"
            id="validId"
            name="validId"
            min="0"
            onKeyPress={preventMinus}
            onPaste={preventPasteNegative}
            onChange={(e) => {
              setValidId(e.target.value);
            }}
            placeholder="আইডি লিখুন"
          />
        </div>
        <div className="mx-4">
          {processing === true ? (
            <button
              type="button"
              className="w-full   my-4 mt-8 px-10 rounded-xl bg-purple-700   text-center"
              disabled
            >
              <span className=" flex justify-center items-center gap-x-6">
                {" "}
                <BtnLoader />
                প্রক্রিয়া করা হচ্ছে, অনুগ্রহ করে অপেক্ষা করুন
              </span>
              {/* <svg
                    className="animate-spin text-white spinner-border h-5 w-5 mr-3"
                    viewBox="0 0 24 24"
                  ></svg> */}
            </button>
          ) : (
            <button
              onClick={ForgetPassword}
              type="submit"
              className="w-full py-4 my-4 mt-8 px-10 bg-purple-400 rounded-xl hover:bg-purple-700 text-white text-center"
            >
              নতুন পাসওয়ার্ড তৈরি করুন
            </button>
          )}
        </div>
        <div className="grid place-items-end">
          <Link to="/">
            <p className="cursor-pointer text-right text-lg p-3  hover:text-slate-300 hover:translate-y-1  duration-700 mt-5">
              {" "}
              লগইন পৃষ্ঠায় ফিরে যান{" "}
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
