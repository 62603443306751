import React from 'react'

const NotFoundPage = () => {
  return (
    <div  >
     <h1 >  </h1>
    </div>
  )
}

export default NotFoundPage