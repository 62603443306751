import React, { useState, useEffect } from "react";
import Loader from "../../Components/Animation/Loader";
import { getAdminApi, putApi } from "../../services/api.service";
import userImg from "../../Assets/Image/userimg.png";
import { Link } from "react-router-dom";
const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const getDataApiCall = async () => {
    //console.log("heatin useeffet");

    try {
      setIsLoading(true);
      // //console.log("try er moddhe");
      const data = await getAdminApi(`admin/noti`);

      //console.log(data?.data, "notidatas");
      setNotificationData(data?.data);
      setIsLoading(false);
    } catch (err) {
      //console.log(err);
    }
  };
  useEffect(() => {
    const callApi = async () => {
      await getDataApiCall();
    };
    callApi();
  }, []);
  const notiUpdateApiCall = async (id) => {
    //console.log("heat");
    const datakey = {
      notiID: id,
      readStatus: "yes",
      //   updateStatus: "yes",
    };
    try {
      //   setIsLoading(true);
      // //console.log("try er moddhe");
      const data = await putApi(`admin/noti`, datakey);

      //console.log(data, "notiupdatedatas");
      //   setNotificationData(data?.data);
      //   setIsLoading(false);
    } catch (err) {
      //console.log(err);
    }
  };
  if (isLoading === true) {
    return (
      <div className=" grid place-items-center xl:h-screen ">
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <h1 className=" mb-5 text-center text-2xl  xl:text-3xl">
        সকল নোটিফিকেশন
      </h1>
      <div>
        {notificationData?.length !== 0 ? (
          <div>
            <div>
              <div className="">
                {notificationData?.map((elem) => (
                  <div
                    key={elem.n_id}
                    className={`${
                      elem.n_read_status === "no"
                        ? "bg-slate-400"
                        : "bg-transparent"
                    }  md:flex justify-between items-center gap-x-3 border border-btncolor w-full p-3 my-2 rounded-md shadow-md text-xs md:text-base overflow-hidden `}
                  >
                    <img
                      className=" w-16 h-16  rounded-full"
                      src={userImg}
                      alt="profileimg"
                    />
                    <div className=" text-justyfy py-2 md:py-0">
                      <p>{elem.n_msg}</p>
                      <div>
                        <p>অনুরোধের তারিখ: {elem.n_time.slice(0, 10)}</p>
                        <p>অনুরোধের সময়: {elem.n_time.slice(11, 19)}</p>
                      </div>
                    </div>
                    <div>
                      {elem?.update_status === "no" ? (
                        <Link
                          to={`/profile/${elem?.details?.admin_id}/${elem?.n_id}`}
                        >
                          <button
                            onClick={() => {
                              notiUpdateApiCall(elem.n_id);
                            }}
                            className=" text-xs md:text-base  px-5 py-2 bg-purple-500 hover:bg-purple-700 rounded-xl"
                          >
                            পাসওয়ার্ড পরিবর্তন করুন
                          </button>
                        </Link>
                      ) : (
                        <button className=" text-xs md:text-base  px-5 py-2 bg-green-500  rounded-xl">
                          ইতিমধ্যে পাসওয়ার্ড পরিবর্তন করা হয়েছে
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className=" grid place-items-center h-screen">
            <h1 className="  mb-5 text-center text-2xl  xl:text-3xl">
              কোনো প্রোফাইল তথ্য পাওয়া যায়নি
            </h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
