const checkValidFile = (file) => {
  const acceptedTypes = ["jpg", "jpeg", "png", "svg", "gif", "psd", "ai"];
  const fileType = file.type.split("/")[1];
  const fileSizeKB = Math.floor(file.size / 1024);
  ////console.log(fileSizeKB)
  //console.log(fileType);
  if (acceptedTypes.includes(fileType) && fileSizeKB < 5 * 1024) {
    return true;
  }
  return false;
};
export default checkValidFile;
