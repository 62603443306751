import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import ProfileCard from "./../../../Components/Profile/ProfileCard";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { hinduComunity } from "../../../Constants/GeoLocation/geoLocation";
import { deleteApi, getDataApi } from "../../../services/api.service";
import Loader from "../../../Components/Animation/Loader";
import { getLocation } from "../../../Utils/AddressFunctions/getLocation";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  // duration: 1000,
};

const Hindhu = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchvalue, setSearchvalue] = useState("");
  const [profileData, setProfileData] = useState("");
  const [pinprofileData, setPinProfileData] = useState([]);

  //console.log(pinprofileData, "pinprofileData");
  const [error, setError] = useState("");
  const [open, setOpen] = React.useState(false);

  const [hinduCom, setHinduCom] = useState([]);
  // const [allOrg, setAllOrg] = useState([]);
  const hinduComData = hinduComunity;
  //console.log(profileData.data, "profileData from islam ");

  const [division, setDivision] = useState("");
  const [district, setDistrict] = useState("");
  const [pdivision, setPDivision] = useState("");
  const [pdistrict, setPDistrict] = useState("");

  const divisionData = getLocation();

  const filtered_t_div = divisionData.filter((elem) => {
    return elem.bn_name === division;
  });
  const districtData = getLocation(filtered_t_div[0]?.id);

  const PdivisionData = getLocation();
  const filtered_p_div = PdivisionData.filter((elem) => {
    return elem.bn_name === pdivision;
  });

  const PdistrictData = getLocation(filtered_p_div[0]?.id);
  // localStorage.setItem("pinHindu", pinprofileData);
  // useEffect(() => {}, []);
  // //console.log(allOrg);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setDivision("");
    setDistrict("");
    setPDivision("");
    setPDistrict("");
    setHinduCom([]);
    setOpen(false);
    setError("");
  };

  const pindatasfromstorage = localStorage.getItem("হিন্দু");
  const pindatas = JSON.parse(pindatasfromstorage);
  // console.log(pindatas, "pindatas");

  const getDataApiCall = async () => {
    const getdata = { profileType: "religion", subType: "হিন্দু" };
    try {
      setIsLoading(true);
      // //console.log("try er moddhe");
      const data = await getDataApi(`profile/all`, getdata);
      if (data.error === true) {
        toast.warning(data.message);
        return null;
      }
      // //console.log("profile islam", data);
      setProfileData(data);
      setIsLoading(false);
    } catch (err) {
      //console.log(err);
    }
  };
  useEffect(() => {
    const callApi = async () => {
      await getDataApiCall();
    };
    callApi();
  }, []);

  const serachapicall = async () => {
    const getdata = {
      profileType: "religion",
      subType: "হিন্দু",
      name: searchvalue,
      nid: searchvalue,
      tin: searchvalue,
      phone1: searchvalue,
      phone2: searchvalue,
    };
    try {
      setIsLoading(true);
      // //console.log("try er moddhe");
      const data = await getDataApi(`profile/search`, getdata);
      if (data.error === true) {
        toast.warning(data.message);
        return null;
      }
      //console.log("profile from search", data?.data);
      setProfileData(data);
      setIsLoading(false);
    } catch (err) {
      //console.log(err);
    }
  };

  const deleteProfile = async (id) => {
    const getdata = { profileType: "religion" };
    try {
      setIsLoading(true);
      // //console.log("try er moddhe");
      const data = await deleteApi(`profile/${id}`, getdata);
      if (data.error === true) {
        toast.warning(data.message);
        return null;
      }
      //console.log("profile islam filter", data?.data);
      getDataApiCall();
      setIsLoading(false);
    } catch (err) {
      //console.log(err);
    }
  };

  const getDataFilterApiCall = async () => {
    // //console.log("division", division);
    // //console.log("district", district);
    // //console.log("pdivision", pdivision);
    // //console.log("pdistrict", pdistrict);
    // //console.log("hinduCom", hinduCom);

    const filterorg = [{ name: hinduCom }];
    const getdata = {
      profileType: "religion",
      subType: "হিন্দু",
      present_add_div: division ? division : null,
      present_add_dist: district ? district : null,
      permanent_add_div: pdivision ? pdivision : null,
      permanent_add_dist: pdistrict ? pdistrict : null,
      organizations: hinduCom.length !== 0 ? filterorg : null,
    };
    try {
      setIsLoading(true);
      // //console.log("try er moddhe");
      const data = await getDataApi(`profile/filter`, getdata);
      if (data.error === true) {
        toast.warning(data.message);
        return null;
      }
      //console.log("profile  filter", data);
      setProfileData(data);
      setIsLoading(false);
    } catch (err) {
      //console.log(err);
    }
    handleClose();
  };

  if (isLoading === true) {
    return (
      <div className=" grid place-items-center xl:h-screen ">
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <div className=" animate-pulse  ">
        <ScrollToTop
          smooth
          width="100%"
          color="white"
          style={{
            background: "linear-gradient(to top, #833ab4, #fd1d1d, #fcb045)",
            zIndex: "9999999 !important",
          }}
          top={150}
        />
      </div>
      <h1 className=" mb-5 text-center text-2xl  xl:text-3xl">
        হিন্দু ধর্মের প্রোফাইল
      </h1>
      <div className=" grid grid-cols-1 xl:grid-cols-2 xl:gap-x-10 gap-y-3 xl:gap-y-0 items-center z-0">
        <div className=" flex ">
          <input
            type="text"
            className="w-full p-3 text-white  bg-transparent border border-btncolor text-sm xl:text-lg  rounded-xl"
            onChange={(e) => {
              setSearchvalue(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                serachapicall();
              }
            }}
            // onKeyPress={handleKeypress}
          />

          <button
            className="whitespace-nowrap text-left xl:text-center -ml-8 p-1 rounded-r-xl bg-purple-600 hover:bg-purple-700  overflow-hidden"
            onClick={serachapicall}
          >
            {" "}
            <SearchIcon fontSize="medium" />
          </button>
        </div>
        <div className="grid grid-cols-2 gap-x-2 xl:gap-x-5  ">
          <button
            className="commonbtn whitespace-nowrap text-left xl:text-center xl:py-2 w-full overflow-hidden"
            onClick={handleOpen}
          >
            <FilterListIcon className=" xl:mr-3" /> Search By filter
          </button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className=" rounded-2xl  ">
              <div
                onClick={handleClose}
                className=" cursor-pointer  text-end  text-sky-500 hover:text-red-500 "
              >
                <CloseIcon fontSize="large" />
              </div>
              <div className=" grid grid-cols-1  xl:grid-cols-2 gap-x-2 overflow-hidden">
                <h2 className=" text-lg xl:text-xl text-slate-900 col-span-2">
                  বর্তমান ঠিকানা
                </h2>
                <div className="w-full col-span-2 xl:col-span-1 ">
                  <h2 className=" text-sm xl:text-lg my-2 text-slate-900">
                    বিভাগ{" "}
                  </h2>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={division}
                      className=" bg-white rounded-xl"
                      onChange={(event) => {
                        setDivision(event.target.value);
                      }}
                      displayEmpty={true}
                      renderValue={(value) =>
                        value?.length ? value : "বিভাগ বাছাই করুন"
                      }
                    >
                      {divisionData.map((elem) => (
                        <MenuItem key={elem.id} value={elem.bn_name}>
                          {elem.bn_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="w-full col-span-2  xl:col-span-1">
                  <h2 className=" text-sm xl:text-lg my-2  text-slate-900">
                    জেলা{" "}
                  </h2>
                  {division.length !== 0 ? (
                    <FormControl fullWidth>
                      <Select
                        value={district}
                        className=" bg-white rounded-xl"
                        onChange={(event) => {
                          setDistrict(event.target.value);
                        }}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? value : "জেলা বাছাই করুন"
                        }
                      >
                        {districtData?.map((elem) => (
                          <MenuItem key={elem.id} value={elem.bn_name}>
                            {elem.bn_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl fullWidth>
                      <Select
                        value={district}
                        className=" bg-white rounded-xl"
                        onChange={(event) => {
                          setDistrict(event.target.value);
                        }}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? value : "জেলা বাছাই করুন"
                        }
                      ></Select>
                    </FormControl>
                  )}
                </div>
                <h2 className="text-lg text-slate-900 xl:text-xl col-span-2 my-3 ">
                  স্থায়ী ঠিকানা
                </h2>
                <div className="w-full col-span-2 xl:col-span-1 ">
                  <h2 className=" text-sm xl:text-lg my-2 text-slate-900">
                    বিভাগ{" "}
                  </h2>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pdivision}
                      className=" bg-white rounded-xl"
                      onChange={(event) => {
                        setPDivision(event.target.value);
                      }}
                      displayEmpty={true}
                      renderValue={(value) =>
                        value?.length ? value : "বিভাগ বাছাই করুন"
                      }
                    >
                      {divisionData.map((elem) => (
                        <MenuItem key={elem.id} value={elem.bn_name}>
                          {elem.bn_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="w-full col-span-2  xl:col-span-1">
                  <h2 className=" text-sm xl:text-lg my-2  text-slate-900">
                    জেলা{" "}
                  </h2>
                  {pdivision.length !== 0 ? (
                    <FormControl fullWidth>
                      <Select
                        value={pdistrict}
                        className=" bg-white rounded-xl"
                        onChange={(event) => {
                          setPDistrict(event.target.value);
                        }}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? value : "জেলা বাছাই করুন"
                        }
                      >
                        {PdistrictData?.map((elem) => (
                          <MenuItem key={elem.id} value={elem.bn_name}>
                            {elem.bn_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl fullWidth>
                      <Select
                        value={pdistrict}
                        className=" bg-white rounded-xl"
                        onChange={(event) => {
                          setPDistrict(event.target.value);
                        }}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value?.length ? value : "জেলা বাছাই করুন"
                        }
                      ></Select>
                    </FormControl>
                  )}
                </div>
                <div className=" col-span-2">
                  <h2 className=" text-sm xl:text-lg my-2 text-slate-900">
                    ধর্মীয় সংগঠন
                  </h2>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={hinduCom}
                      className=" bg-white rounded-xl"
                      onChange={(event) => {
                        setHinduCom([event.target.value]);
                      }}
                      displayEmpty={true}
                      renderValue={(value) =>
                        value?.length
                          ? value
                          : "হিন্দু ধর্মীয় সংগঠন ভিত্তিক প্রোফাইল দেখার জন্য নির্বাচন করুন"
                      }
                    >
                      {hinduComData.map((elem) => (
                        <MenuItem key={elem.id} value={elem.name}>
                          {elem.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className=" col-span-2 py-2">
                  <p className=" text-md text-red-600 font-semibold">{error}</p>
                </div>
                <div className=" py-2 w-full ">
                  <button
                    onClick={getDataFilterApiCall}
                    className=" w-40 text-sm md:text-base whitespace-nowrap rounded-2xl px-3 py-2 bg-indigo-500 hover:bg-green-500 "
                  >
                    খুঁজুন
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
          <div>
            <Link to="/add_hindu_profile">
              <button className="commonbtn whitespace-nowrap text-left xl:text-center xl:py-2 overflow-hidden w-full">
                <PersonAddIcon className=" xl:mr-3" /> Add Profile
              </button>
            </Link>
          </div>
        </div>
      </div>
      {pindatas && (
      <div>
          {pindatas?.length !== 0 ? (
            <div>
              <h2 className=" text-lg xl:text-xl py-5">পিন প্রোফাইলগুলি</h2>
              <div className=" border border-btncolor shadow-md rounded-md p-5">
                <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5  gap-x-5 z-0">
                  {pindatas?.map((prfiles) => (
                    <ProfileCard
                      key={prfiles?.u_id}
                      id={prfiles?.u_id}
                      img={prfiles?.u_pro_img}
                      name={prfiles?.u_name}
                      designation={prfiles?.u_profession}
                      phone={prfiles?.u_phone1}
                      email={prfiles?.u_id}
                      nid={prfiles?.u_nid}
                      address={prfiles?.u_p_division}
                      religion={prfiles?.u_religion}
                      deleteProfile={deleteProfile}
                      alldata={prfiles}
                      pinprofileData={pinprofileData}
                      setPinProfileData={setPinProfileData}
                    />
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
        
      {profileData?.data?.length !== 0 ? (
        <div className=" pb-10">
          <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-5 my-5 gap-x-5 z-0">
            {profileData?.data?.map((prfiles) => (
              <ProfileCard
                key={prfiles?.u_id}
                id={prfiles?.u_id}
                img={prfiles?.u_pro_img}
                name={prfiles?.u_name}
                designation={prfiles?.u_profession}
                phone={prfiles?.u_phone1}
                email={prfiles?.u_id}
                nid={prfiles?.u_nid}
                address={prfiles?.u_p_division}
                religion={prfiles?.u_religion}
                deleteProfile={deleteProfile}
                alldata={prfiles}
                pinprofileData={pinprofileData}
                setPinProfileData={setPinProfileData}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className=" grid place-items-center h-screen">
          <h1 className="  mb-5 text-center text-2xl  xl:text-3xl">
            কোনো প্রোফাইল তথ্য পাওয়া যায়নি
          </h1>
        </div>
      )}
    </div>
  );
};

export default Hindhu;
