import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PersonIcon from "@mui/icons-material/Person";
import AppsIcon from "@mui/icons-material/Apps";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";

import { toast } from "react-toastify";
import Loader from "../../Components/Animation/Loader";
import { getAdminApi } from "./../../services/api.service";
const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //console.log(dashboardData, "dashboardData");

  const getDataApiCall = async () => {
    //console.log("heatin useeffet");
    const getdata = {};

    try {
      setIsLoading(true);
      // //console.log("try er moddhe");
      const data = await getAdminApi(`profile/dashboard`);
      if (data.error === true) {
        toast.warning(data.message);
        return null;
      }
      setDashboardData(data?.data);
      // setPoliProfileData();
      setIsLoading(false);
    } catch (err) {
      //console.log(err);
    }
  };
  useEffect(() => {
    const callApi = async () => {
      await getDataApiCall();
    };
    callApi();
  }, []);
  if (isLoading === true) {
    return (
      <div className=" grid place-items-center xl:h-screen ">
        <Loader />
      </div>
    );
  }

  let admintype = localStorage.getItem("is_super_admin");
  return (
    <div className="font-body pt-4 pb-10">
      {/* totalAdmins : 14 totalBuddhas : 2 totalChristians : 2 totalHindus : 2
      totalIrProfiles : 6 totalIslams : 23 totalLabours : 28 totalMinistries : 1
      totalOtherReligions : 2 totalOtherRmg : 1 totalOwners : 2 totalRelProfiles
      : 31 totalRmgProfiles : 32 totalUnreadNotifications : 2 */}
      <h1 className=" text-center text-xl xl:text-3xl pb-3">
        এডমিন ড্যাশবোর্ড
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-y-2 md:gap-4  xl:grid-cols-3 xl:gap-8 mx-4 text-center">
        {admintype === "yes" ? (
          <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
            <div className="">
              <p className="text-slate-100 text-base xl:text-xl">
                মোট এডমিন প্রোফাইল সংখ্যা
              </p>
              <p className="text-xl xl:text-4xl py-2 font-bold">
                {dashboardData?.totalAdmins}
              </p>
              <Link to="/admins">
                <button className="  bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                  দেখতে ক্লিক করুন{" "}
                </button>
              </Link>
            </div>
            {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
          </div>
        ) : null}
        {admintype === "yes" ? (
          <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
            <div className="">
              <p className="text-slate-100 text-base xl:text-xl">
                মোট অপঠিত নোটিফিকেশন সংখ্যা
              </p>
              <p className="text-xl xl:text-4xl py-2 font-bold text-red-500">
                {dashboardData?.totalUnreadNotifications}
              </p>
              <Link to="/notifications">
                <button className=" bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                  দেখতে ক্লিক করুন{" "}
                </button>
              </Link>
            </div>
            {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
          </div>
        ) : null}

        <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
          <div className="">
            <p className="text-slate-100 text-base xl:text-xl">
              মোট ধর্মের প্রোফাইল সংখ্যা
            </p>
            <p className="text-xl xl:text-4xl py-2 font-bold">
              {dashboardData?.totalRelProfiles}
            </p>
            <Link to="/religions">
              <button className=" bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                দেখতে ক্লিক করুন{" "}
              </button>
            </Link>
          </div>
          {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
        </div>
        <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
          <div className="">
            <p className="text-slate-100 text-base xl:text-xl">
              মোট আর এম জি প্রোফাইল সংখ্যা
            </p>
            <p className="text-xl xl:text-4xl py-2 font-bold">
              {dashboardData?.totalRmgProfiles}
            </p>
            <Link to="/rmg">
              <button className=" bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                দেখতে ক্লিক করুন{" "}
              </button>
            </Link>
          </div>
          {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
        </div>
        <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
          <div className="">
            <p className="text-slate-100 text-base xl:text-xl">
              মোট আন্তর্জাতিক বিষয়ক প্রোফাইল সংখ্যা
            </p>
            <p className="text-xl xl:text-4xl py-2 font-bold">
              {dashboardData?.totalIrProfiles}
            </p>
            <Link to="/irprofiles">
              <button className=" bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                দেখতে ক্লিক করুন{" "}
              </button>
            </Link>
          </div>
          {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
        </div>

        <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
          <div className="">
            <p className="text-slate-100 text-base xl:text-xl">
              মোট ইসলাম ধর্মের প্রোফাইল সংখ্যা
            </p>
            <p className="text-xl xl:text-4xl py-2 font-bold">
              {dashboardData?.totalIslams}
            </p>
            <Link to="/islam">
              <button className=" bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                দেখতে ক্লিক করুন{" "}
              </button>
            </Link>
          </div>
          {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
        </div>
        <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
          <div className="">
            <p className="text-slate-100 text-base xl:text-xl">
              মোট হিন্দু ধর্মের প্রোফাইল সংখ্যা
            </p>
            <p className="text-xl xl:text-4xl py-2 font-bold">
              {dashboardData?.totalHindus}
            </p>
            <Link to="/hindu">
              <button className=" bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                দেখতে ক্লিক করুন{" "}
              </button>
            </Link>
          </div>
          {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
        </div>
        <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
          <div className="">
            <p className="text-slate-100 text-base xl:text-xl">
              মোট বৌদ্ধ ধর্মের প্রোফাইল সংখ্যা
            </p>
            <p className="text-xl xl:text-4xl py-2 font-bold">
              {dashboardData?.totalBuddhas}
            </p>
            <Link to="/buddha">
              <button className=" bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                দেখতে ক্লিক করুন{" "}
              </button>
            </Link>
          </div>
          {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
        </div>

        <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
          <div className="">
            <p className="text-slate-100 text-base xl:text-xl">
              মোট খ্রিষ্টান ধর্মের প্রোফাইল সংখ্যা
            </p>
            <p className="text-xl xl:text-4xl py-2 font-bold">
              {dashboardData?.totalChristians}
            </p>
            <Link to="/cristan">
              <button className=" bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                দেখতে ক্লিক করুন{" "}
              </button>
            </Link>
          </div>
          {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
        </div>
        <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
          <div className="">
            <p className="text-slate-100 text-base xl:text-xl">
              অন্যান্য ধর্মের প্রোফাইল সংখ্যা
            </p>
            <p className="text-xl xl:text-4xl py-2 font-bold">
              {dashboardData?.totalOtherReligions}
            </p>
            <Link to="/others_religion">
              <button className=" bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                দেখতে ক্লিক করুন{" "}
              </button>
            </Link>
          </div>
          {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
        </div>
        <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
          <div className="">
            <p className="text-slate-100 text-base xl:text-xl">
              মোট গার্মেন্টস মালিক প্রোফাইল সংখ্যা
            </p>
            <p className="text-xl xl:text-4xl py-2 font-bold">
              {dashboardData?.totalOwners}
            </p>
            <Link to="/garments_owners">
              <button className=" bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                দেখতে ক্লিক করুন{" "}
              </button>
            </Link>
          </div>
          {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
        </div>
        <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
          <div className="">
            <p className="text-slate-100 text-base xl:text-xl">
              মোট গার্মেন্টস শ্রমিক প্রোফাইল সংখ্যা
            </p>
            <p className="text-xl xl:text-4xl py-2 font-bold">
              {dashboardData?.totalAdmins}
            </p>
            <Link to="/grament_labour">
              <button className=" bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                দেখতে ক্লিক করুন{" "}
              </button>
            </Link>
          </div>
          {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
        </div>
        <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
          <div className="">
            <p className="text-slate-100 text-base xl:text-xl">
              মোট মন্ত্রনালয় প্রোফাইল সংখ্যা
            </p>
            <p className="text-xl xl:text-4xl py-2 font-bold">
              {dashboardData?.totalMinistries}
            </p>
            <Link to="/ministry_profile">
              <button className=" bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                দেখতে ক্লিক করুন{" "}
              </button>
            </Link>
          </div>
          {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
        </div>

        <div className=" text-white border border-btncolor rounded-lg p-2 md:p-6 overflow-hidden">
          <div className="">
            <p className="text-slate-100 text-base xl:text-xl">
              অন্যান্য আর এম জি প্রোফাইল সংখ্যা
            </p>
            <p className="text-xl xl:text-4xl py-2 font-bold">
              {dashboardData?.totalOtherRmg}
            </p>
            <Link to="/others_rmg_profile">
              <button className=" bg-btncolor hover:bg-purple-700 rounded-lg   text-center text-sm md:text-base xl:text-lg  p-2 break-words w-full">
                দেখতে ক্লিক করুন{" "}
              </button>
            </Link>
          </div>
          {/* <RemoveRedEyeIcon className="text-5xl self-center ml-12" /> */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
