import React, { useState, useEffect } from "react";
import userimg from "../../Assets/Image/userimg.png";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { toast } from "react-toastify";
import validator from "validator";
import { getAdminApi, getDataApi, putApi } from "./../../services/api.service";
import { useParams } from "react-router-dom";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import BtnLoader from "../../Components/Animation/BtnLoader";
const Profile = () => {
  const params = useParams();
  const [value, setValue] = useState({
    oldpassword: "",
    newpassword: "",
    renewpassword: "",
  });
  const admin_id = localStorage.getItem("admin_id");
  const { adminId, nId } = params;

  //console.log(adminId, "adminId", nId, "nId");
  const [admindata, setAdmindata] = useState("");
  //console.log(admindata, "admindata");
  // let navigate = useNavigate();
  // Somewhere in your code, e.g. inside a handler:

  // const handleValueChange = (event: any) => {
  //     setValue(event.target.value);
  // };
  const [errorMessage, setErrorMessage] = useState(false);
  const [validMessage, setValidMessage] = useState(false);
  // const [emptyMessage, setEmptyMessage] = useState("");
  const [oldpasswordShown, setOldPasswordShown] = useState(false);
  const [newpasswordShown, setNewPasswordShown] = useState(false);
  const [repasswordShown, setRePasswordShown] = useState(false);

  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      // setErrorMessage("");
      setValidMessage(false);
      // toast.warning("password valid");
    } else {
      // toast.warning("password is not valid");
      setValidMessage(true);
      // setErrorMessage(
      //   "পাসওয়ার্ডের মধ্যে একটি স্পেশাল সিম্বল(@#$..) এবং একটি বড় হাতের অক্ষর(A-Z),একটি ছোট হাতের অক্ষর(a-z), একটি সংখ্যা(0-9) থাকতে হবে"
      // );
    }
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    validate(value);
    setValue((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const getDataApiCall = async () => {
    //console.log("heatin useeffet");

    try {
      // //console.log("try er moddhe");
      if (adminId) {
        let data = await getAdminApi(`admin/${adminId}`);

        if (data.error === true) {
          toast.warning(data.message);
          return null;
        }
        setAdmindata(data.data);
        //console.log(data, "admin details");
      } else {
        let data = await getAdminApi(`admin/${admin_id}`);

        if (data.error === true) {
          toast.warning(data.message);
          return null;
        }

        //console.log(data, "admin details");
        setAdmindata(data.data);
      }
    } catch (err) {
      //console.log(err);
    }
  };
  useEffect(() => {
    const callApi = async () => {
      await getDataApiCall();
    };
    callApi();
  }, []);
  const notiUpdateApiCall = async () => {
    //console.log("heat");
    const datakey = {
      notiID: nId,
      // readStatus: "yes",
      updateStatus: "yes",
    };
    try {
      //   setIsLoading(true);
      // //console.log("try er moddhe");
      const data = await putApi(`admin/noti`, datakey);

      //console.log(data, "notiupdatedatas");
      //   setNotificationData(data?.data);
      //   setIsLoading(false);
    } catch (err) {
      //console.log(err);
    }
  };
  const [processing, setProcessing] = useState(false);
  const changePassword = async () => {
    setProcessing(true);
    const values = value.newpassword;
    //console.log(value.newpassword.length);
    // if (value.newpassword.length === 0 && value.renewpassword.length === 0) {
    //   // setErrorMessage(true);
    //   setProcessing(false);
    //   toast.success("must be fill password fill");
    // } else {
    //   // setErrorMessage(false);
    // }
    if (
      value.newpassword === value.renewpassword &&
      value.newpassword.length > 0 &&
      value.renewpassword.length > 0 &&
      validator.isStrongPassword(values, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorMessage(false);
    } else {
      setErrorMessage(true);
      setProcessing(false);
      return null;
    }
    const getdata = {
      id: admin_id,
      admin_validation: admindata?.admin_validation,
      prevPassword: value.oldpassword,
      updatedPassword: value.newpassword,
    };
    const getNotidata = {
      id: adminId,
      admin_validation: admindata?.admin_validation,
      updatedPassword: value.newpassword,
    };
    try {
      // //console.log("try er moddhe");
      let data = "";
      if (adminId && nId) {
        data = await getDataApi(`admin/otherPassd`, getNotidata);
      } else {
        data = await getDataApi(`admin/pssd`, getdata);
      }
      if (data.error === true) {
        // toast.warning(data.message);
        setProcessing(false);
        return null;
      }
      if (data.success === true) {
        notiUpdateApiCall();
        toast.success("পাসওয়ার্ড সফলভাবে পরিবর্তিত হয়েছে");
        setProcessing(false);
      }

      //console.log("pass change data", data);
    } catch (err) {
      //console.log(err);
    }
  };

  return (
    <div className=" grid grid-cols-1 xl:grid-cols-2 gap-y-5 xl:gap-10 ">
      <div className=" animate__animated  animate__backInLeft   border-2  border-btncolor shadow-md rounded-md font-body  ">
        <div className=" m-2  text-white">
          <div className=" grid place-items-center">
            <img
              className=" w-24 xl:w-32 h-24 xl:h-32  rounded-full"
              src={userimg}
              // admin_img
              alt="userimage"
            />
            <h1 className=" text-sm md:text-lg bg-btncolor font-mono px-4 py-2 rounded-2xl mt-4 xl:text-2xl ">
              {admindata.is_super_admin === "yes" ? " সুপার এডমিন" : "এডমিন"}
            </h1>
          </div>
          <div className=" m-2 xl:m-10 grid grid-cols-1 gap-3  xl:gap-8  ">
            <h1 className=" bg-btncolor  p-2 rounded-xl text-sm md:text-lg xl:text-2xl overflow-hidden ">
              {" "}
              <span className="pr-4 ">
                {" "}
                <AccountCircleOutlinedIcon fontSize="large" />{" "}
              </span>{" "}
              {admindata.admin_name}
            </h1>
            <h1 className=" bg-btncolor  p-2 rounded-xl text-sm md:text-lg xl:text-2xl overflow-hidden ">
              {" "}
              <span className="pr-4 ">
                {" "}
                <BadgeOutlinedIcon fontSize="large" />{" "}
              </span>{" "}
              {admindata.admin_validation}
            </h1>
            {/* <h1 className="bg-btncolor  p-2 rounded-xl text-sm md:text-lg xl:text-2xl overflow-hidden ">
              {" "}
              <span className=" pr-4">
                <EmailOutlinedIcon fontSize="large" />{" "}
              </span>{" "}
              ebrahim@gmail.com
            </h1>
            <h1 className="bg-btncolor  p-2 rounded-xl text-sm md:text-lg xl:text-2xl overflow-hidden ">
              {" "}
              <span className=" pr-4">
                <PhoneAndroidOutlinedIcon fontSize="large" />{" "}
              </span>{" "}
              +880 01611341799
            </h1> */}
            {/* <h1 className="bg-btncolor  p-2 rounded-xl text-sm md:text-lg xl:text-2xl overflow-hidden ">
              {" "}
              <span className=" pr-4">
                <HomeOutlinedIcon fontSize="large" />{" "}
              </span>{" "}
              Uttara ,Dahaka Bangladesh.
            </h1> */}
          </div>
        </div>
      </div>
      <div className="animate__animated  animate__backInRight border-2  border-btncolor shadow-md rounded-md font-body  ">
        <div className=" grid place-items-center font-body">
          <div className="m-2 p-2  text-white">
            <div className="xl:mx-4">
              <h1 className="mb-12 text-base md:text-lg xl:text-2xl font-mono text-center">
                পাসওয়ার্ড পরিবর্তন করুন
              </h1>
              <div className=" grid grid-cols-1 gap-8">
                {adminId && nId ? (
                  <div>
                    <div>
                      {" "}
                      <label>আপনার নতুন পাসওয়ার্ড লিখুন</label>
                      <input
                        className="w-full py-3 px-2 my-2 bg-white text-base xl:text-xl text-black"
                        type={newpasswordShown ? "text" : "password"}
                        id="newpassword"
                        name="newpassword"
                        onChange={handleChange}
                        placeholder="পাসওয়ার্ড লিখুন"
                      />
                      <input
                        id="newcheckbox"
                        type="checkbox"
                        checked={newpasswordShown}
                        onChange={(e) => {
                          setNewPasswordShown(!newpasswordShown);
                        }}
                      />{" "}
                      <label htmlFor="newcheckbox">Show password?</label>
                      {/* {value.newpassword.length === 0 ? (
                    <p>ইনপুট ফিল্ড খালি থাকা যাবে না</p>
                  ) : (
                    <p></p>
                  )} */}
                    </div>
                    <div>
                      <label>আপনার নতুন পাসওয়ার্ড পুনরায় লিখুন</label>
                      <input
                        className="w-full py-3 px-2 my-2 bg-white text-base xl:text-xl text-black"
                        type={repasswordShown ? "text" : "password"}
                        id="renewpassword"
                        name="renewpassword"
                        onChange={handleChange}
                        placeholder="পাসওয়ার্ড লিখুন"
                      />
                      <input
                        id="recheckbox"
                        type="checkbox"
                        checked={repasswordShown}
                        onChange={(e) => {
                          setRePasswordShown(!repasswordShown);
                        }}
                      />{" "}
                      <label htmlFor="recheckbox">Show password?</label>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <label>আপনার পুরানো পাসওয়ার্ড লিখুন</label>
                      <input
                        className="w-full py-3 px-2 my-2 bg-white text-base xl:text-xl text-black"
                        type={oldpasswordShown ? "text" : "password"}
                        id="oldpassword"
                        name="oldpassword"
                        onChange={handleChange}
                        placeholder="পাসওয়ার্ড লিখুন"
                      />
                      <input
                        id="checkbox"
                        type="checkbox"
                        checked={oldpasswordShown}
                        onChange={(e) => {
                          setOldPasswordShown(!oldpasswordShown);
                        }}
                      />{" "}
                      <label htmlFor="checkbox">Show password?</label>
                      {/* <button onClick={togglePassword}>Show Password</button> */}
                    </div>
                    <div>
                      {" "}
                      <label>আপনার নতুন পাসওয়ার্ড লিখুন</label>
                      <input
                        className="w-full py-3 px-2 my-2 bg-white text-base xl:text-xl text-black"
                        type={newpasswordShown ? "text" : "password"}
                        id="newpassword"
                        name="newpassword"
                        onChange={handleChange}
                        placeholder="পাসওয়ার্ড লিখুন"
                      />
                      <input
                        id="newcheckbox"
                        type="checkbox"
                        checked={newpasswordShown}
                        onChange={(e) => {
                          setNewPasswordShown(!newpasswordShown);
                        }}
                      />{" "}
                      <label htmlFor="newcheckbox">Show password?</label>
                      {/* {value.newpassword.length === 0 ? (
                    <p>ইনপুট ফিল্ড খালি থাকা যাবে না</p>
                  ) : (
                    <p></p>
                  )} */}
                    </div>
                    <div>
                      <label>আপনার নতুন পাসওয়ার্ড পুনরায় লিখুন</label>
                      <input
                        className="w-full py-3 px-2 my-2 bg-white text-base xl:text-xl text-black"
                        type={repasswordShown ? "text" : "password"}
                        id="renewpassword"
                        name="renewpassword"
                        onChange={handleChange}
                        placeholder="পাসওয়ার্ড লিখুন"
                      />
                      <input
                        id="recheckbox"
                        type="checkbox"
                        checked={repasswordShown}
                        onChange={(e) => {
                          setRePasswordShown(!repasswordShown);
                        }}
                      />{" "}
                      <label htmlFor="recheckbox">Show password?</label>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="m-4 text-justify">
              {/* <p className=" text-sm py-2">
                পাসওয়ার্ডের মধ্যে একটি স্পেশাল সিম্বল (@#$..) এবং একটি বড় হাতের
                অক্ষর (A-Z) , একটি ছোট হাতের অক্ষর (a-z) , একটি সংখ্যা (0-9) ও
                মিনিমাম ৮ অক্ষর থাকতে হবে
              </p> */}
              {validMessage === true ? (
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                  }}
                >
                  {/* {errorMessage} */}
                  <p className=" text-sm py-2">
                    পাসওয়ার্ডের মধ্যে একটি স্পেশাল সিম্বল (@#$..) এবং একটি বড়
                    হাতের অক্ষর (A-Z) , একটি ছোট হাতের অক্ষর (a-z) , একটি সংখ্যা
                    (0-9) ও মিনিমাম ৮ অক্ষর থাকতে হবে
                  </p>
                </span>
              ) : (
                <></>
              )}
            </div>

            <div className="mx-4">
              {processing === true ? (
                <button
                  type="button"
                  className="w-full   my-4 mt-8 px-10 rounded-xl bg-purple-700   text-center"
                  disabled
                >
                  <span className=" flex justify-center items-center gap-x-6">
                    {" "}
                    <BtnLoader />
                    প্রক্রিয়া করা হচ্ছে, অনুগ্রহ করে অপেক্ষা করুন
                  </span>
                  {/* <svg
                    className="animate-spin text-white spinner-border h-5 w-5 mr-3"
                    viewBox="0 0 24 24"
                  ></svg> */}
                </button>
              ) : (
                <div>
                  {value.newpassword === value.renewpassword &&
                  validMessage === false ? (
                    <button
                      onClick={changePassword}
                      type="submit"
                      className="w-full py-4 my-4  px-10 rounded-xl bg-purple-600 hover:bg-purple-700 text-white text-center"
                    >
                      পাসওয়ার্ড পরিবর্তন
                    </button>
                  ) : (
                    <button
                      // onClick={changePassword}
                      // type="submit"
                      disabled
                      className=" cursor-not-allowed w-full py-4 my-4  px-10 rounded-xl bg-gray-400 text-white text-center"
                    >
                      পাসওয়ার্ড পরিবর্তন
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
