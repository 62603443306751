import { Button } from "@mui/material";
import React from "react";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import MenuIcon from "@mui/icons-material/Menu";
const ShowSidebar = ({ showSidebar, setShowSidebar, toggleSidebar }) => {
  return (
    <div>
      <nav className="bg-light-blue-500 md:ml-72 py-6 px-3">
        <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
          <div className="md:hidden">
            <button className="z-10" onClick={toggleSidebar}>
              <MenuIcon color="secondary" fontSize="large" />
            </button>
          </div>

          {/* <div className="flex justify-between items-center w-full">
            <h4 className="uppercase text-white text-sm tracking-wider mt-1">
              {location === "/"
                ? "DASHBOARD"
                : location.toUpperCase().replace("/", "")}
            </h4>

            <div className="flex">
              <NavbarInput placeholder="Search" />

              <div className="-mr-4 ml-6">
                <Dropdown
                  color="transparent"
                  buttonText={
                    <div className="w-12">
                      <Image src={ProfilePicture} rounded />
                    </div>
                  }
                  rounded
                  style={{
                    padding: 0,
                    color: "transparent",
                  }}
                >
                  <DropdownItem color="lightBlue">Action</DropdownItem>
                  <DropdownItem color="lightBlue">Another Action</DropdownItem>
                  <DropdownItem color="lightBlue">Something Else</DropdownItem>
                </Dropdown>
              </div>
            </div>
          </div> */}
        </div>
      </nav>
    </div>
  );
};

export default ShowSidebar;
