import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useState } from "react";
import { toast } from "react-toastify";
// import { adminLogin } from "./../../services/api.service";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import { getDataApi, imageUploadApi } from "../../../services/api.service";
import { labourComunity } from "./../../../Constants/GeoLocation/geoLocation";
import { getLocation } from "./../../../Utils/AddressFunctions/getLocation";
import { BASE_URL } from "../../../Utils/constants";
import axios from "axios";
import BtnLoader from "../../../Components/Animation/BtnLoader";
import { imgErrorMsg } from "../../../Constants/otherConstants";
import checkValidFile from "../../../Utils/checkValidFile";
const AddgarmentsLabour = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState({
    u_name: "",
    u_type: "শ্রমিক",
    u_father_name: "কোনো তথ্য নাই",
    u_no_of_children: 0,
    u_education: "কোনো তথ্য নাই",

    u_nationality: "কোনো তথ্য নাই",
    u_religion: "কোনো তথ্য নাই",
    u_profession: "কোনো তথ্য নাই",

    u_p_upozilla: "কোনো তথ্য নাই",
    u_p_post_code: "কোনো তথ্য নাই",
    u_p_others: "কোনো তথ্য নাই",

    u_t_upozilla: "কোনো তথ্য নাই",
    u_t_post_code: "কোনো তথ্য নাই",
    u_t_others: "কোনো তথ্য নাই",
    u_nid: 0,

    u_passport: "কোনো তথ্য নাই",

    u_d_license: "কোনো তথ্য নাই",
    u_tin: "কোনো তথ্য নাই",
    u_phone1: 0,
    u_phone2: 0,
    u_fb_link: "কোনো তথ্য নাই",
    u_twitter_lnk: "কোনো তথ্য নাই",
    u_whatsapp_no: 0,
    u_sthabor_property: "কোনো তথ্য নাই",
    u_osthabor_property: "কোনো তথ্য নাই",
    u_family_info: "কোনো তথ্য নাই",
    u_others_org: "",
    u_anual_income: 0,
    u_tax: "কোনো তথ্য নাই",
    u_mamla: "কোনো তথ্য নাই",
    u_car: "কোনো তথ্য নাই",
    u_travel: "কোনো তথ্য নাই",
    u_political: "কোনো তথ্য নাই",
    u_election: "কোনো তথ্য নাই",

    u_influence_org: "কোনো তথ্য নাই",
    u_local_influence: "কোনো তথ্য নাই",

    u_frnds: "কোনো তথ্য নাই",
    u_enemy: "কোনো তথ্য নাই",
    u_connectivity: "কোনো তথ্য নাই",
    u_corruption: "কোনো তথ্য নাই",
    u_negatives: "কোনো তথ্য নাই",
    u_others: "কোনো তথ্য নাই",

    u_comment: "কোনো তথ্য নাই",
  });
  // //console.log("islam profile er ADD FORM data", value);

  const [profileImg, setProfileImg] = useState({ file: "", link: "" });
  const [u_nid_img, setU_nid_img] = useState({ file: "", link: "" });
  const [u_passport_img, set_passport_img] = useState({ file: "", link: "" });
  const [u_d_license_img, set_d_license_img] = useState({ file: "", link: "" });
  const [u_tin_img, set_tin_img] = useState({ file: "", link: "" });

  const [imageValidationStatus, setImgValidationStatus] = useState({
    proImg: true,
    nidImg: true,
    passportImg: true,
    dLicense: true,
    tinImg: true,
  });
  //console.log(imageValidationStatus);

  const [date, setDate] = useState(null);

  const [genderValue, setGenderValue] = useState("পুরুষ");
  const [marriedValue, setMarriedValue] = useState("বিবাহিত");

  const [organizationCom, setorganizationCom] = useState([]);
  const [allOrg, setAllOrg] = useState([]);
  const [error, setError] = useState(false);
  const [errorphone, setErrorphone] = useState(false);
  const organizationComData = labourComunity;
  // let arrislam = [];
  // const filtered_ComData = organizationComData.filter((elem) => {
  //   return elem.bn_name === organizationCom;
  // });

  // //console.log(filtered_ComData, "filtered_ComData");
  //console.log(organizationCom, "organizationCom");

  const [division, setDivision] = useState("");
  const [district, setDistrict] = useState("");
  const [upazila, setUpazila] = useState("");
  const [postoffice, setPostoffice] = useState("");

  const divisionData = getLocation();
  const filtered_t_div = divisionData.filter((elem) => {
    return elem.bn_name === division;
  });
  const districtData = getLocation(filtered_t_div[0]?.id);
  const upazilaData = getLocation(division, district);
  const postofficeData = getLocation(division, district, upazila);

  const [permenentdivision, setPermenentDivision] = useState("");

  // //console.log(permenentdivision, "permenentdivision");
  const [permenentdistrict, setPermenentDistrict] = useState("");
  const [permenentupazila, setPermenentUpazila] = useState("");
  const [permenentpostoffice, setPermenentPostoffice] = useState("");

  const permenentdivisionData = getLocation();
  const filtered_p_div = permenentdivisionData.filter((elem) => {
    return elem.bn_name === permenentdivision;
  });
  // //console.log(filtered_p_div[0]?.id, "filtered");
  const permenentdistrictData = getLocation(filtered_p_div[0]?.id);

  // const permenentupazilaData = getLocation(
  //   permenentdivision,
  //   permenentdistrict
  // );
  // const permenentpostofficeData = getLocation(
  //   permenentdivision,
  //   permenentdistrict,
  //   permenentupazila
  // );
  // //console.log(postoffice, "postofficeData");

  const handleCommunityChange = (event) => {
    const {
      target: { value },
    } = event;
    setorganizationCom(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    let findArray = [];
    value.map((val) => {
      for (let i = 0; i < organizationComData.length; i += 1) {
        if (val === organizationComData[i].name) {
          findArray = [...findArray, organizationComData[i]];
        }
      }
    });
    setAllOrg(findArray);
  };
  //console.log(allOrg);
  const handlegenderChange = (event) => {
    setGenderValue(event.target.value);
  };
  const handleMarriedStatusChange = (event) => {
    setMarriedValue(event.target.value);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setValue((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const onProfileImgChange = async (e) => {
    const file = e.target.files[0];
    ////console.log(checkValidFile(file))
    const validStatus = checkValidFile(file);

    setImgValidationStatus({ ...imageValidationStatus, proImg: validStatus });

    if (validStatus) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const AdminToken = localStorage.getItem("adminToken");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${AdminToken}`);

        const baseUrl = BASE_URL || "http://localhost:5005";
        const url = `${baseUrl}upload`;

        const res = await axios.post(url, formData, config);

        //console.log(res);
        if (res.data.success) {
          setProfileImg({
            ...profileImg,
            link: res.data.data[0].path,
            file: res.data.data[0].originalname,
          });
          toast.success("সফলভাবে ছবি আপলোড হয়েছে ");
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
  const onNid_imgChange = async (e) => {
    const file = e.target.files[0];

    const validStatus = checkValidFile(file);

    setImgValidationStatus({ ...imageValidationStatus, nidImg: validStatus });

    if (validStatus) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const AdminToken = localStorage.getItem("adminToken");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${AdminToken}`);

        const baseUrl = BASE_URL || "http://localhost:5005";
        const url = `${baseUrl}upload`;

        const res = await axios.post(url, formData, config);

        //console.log(res);
        if (res.data.success) {
          setU_nid_img({
            ...u_nid_img,
            link: res.data.data[0].path,
            file: res.data.data[0].originalname,
          });
          toast.success("সফলভাবে ছবি আপলোড হয়েছে ");
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
  const on_passport_imgChange = async (e) => {
    const file = e.target.files[0];

    const validStatus = checkValidFile(file);

    setImgValidationStatus({
      ...imageValidationStatus,
      passportImg: validStatus,
    });

    if (validStatus) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const AdminToken = localStorage.getItem("adminToken");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${AdminToken}`);

        const baseUrl = BASE_URL || "http://localhost:5005";
        const url = `${baseUrl}upload`;

        const res = await axios.post(url, formData, config);

        //console.log(res);
        if (res.data.success) {
          set_passport_img({
            ...u_passport_img,
            link: res.data.data[0].path,
            file: res.data.data[0].originalname,
          });
          toast.success("সফলভাবে ছবি আপলোড হয়েছে ");
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
  const onu_d_license_imgChange = async (e) => {
    const file = e.target.files[0];
    const validStatus = checkValidFile(file);

    setImgValidationStatus({ ...imageValidationStatus, dLicense: validStatus });
    if (validStatus) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const AdminToken = localStorage.getItem("adminToken");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${AdminToken}`);

        const baseUrl = BASE_URL || "http://localhost:5005";
        const url = `${baseUrl}upload`;

        const res = await axios.post(url, formData, config);

        //console.log(res);
        if (res.data.success) {
          set_d_license_img({
            ...u_d_license_img,
            link: res.data.data[0].path,
            file: res.data.data[0].originalname,
          });
          toast.success("সফলভাবে ছবি আপলোড হয়েছে ");
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };
  const onu_tin_imgChange = async (e) => {
    const file = e.target.files[0];

    const validStatus = checkValidFile(file);

    setImgValidationStatus({ ...imageValidationStatus, tinImg: validStatus });

    if (validStatus) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        const AdminToken = localStorage.getItem("adminToken");
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${AdminToken}`);

        const baseUrl = BASE_URL || "http://localhost:5005";
        const url = `${baseUrl}upload`;

        const res = await axios.post(url, formData, config);

        //console.log(res);
        if (res.data.success) {
          set_tin_img({
            ...u_tin_img,
            link: res.data.data[0].path,
            file: res.data.data[0].originalname,
          });
          toast.success("সফলভাবে ছবি আপলোড হয়েছে ");
        }
      } catch (error) {
        //console.log(error);
      }
    }
  };

  const preventMinus = (e) => {
    if (e.code === "Minus") {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };
  let pattern = /^(?:\+88|88)?(01[3-9]\d{8})$/;
  let phone = pattern.test(value.u_phone1);
  let phone2 = pattern.test(value.u_phone2);
  let whatsapp = pattern.test(value.u_whatsapp_no);
  // //console.log(phone, "phone");

  // //console.log(image.profileImgfile.name, "user image file name paisi");
  // const uploadImage = async () => {
  //   const datakey = {
  //     profileImg: image.profileImgfile,
  //     u_nid_img: image.nidimgfile,
  //     u_passport_img: image.passportimgfile,
  //     u_d_license_img: image.dlicensefile,
  //     u_tin_img: image.tinimgfile,
  //   };
  //   let arrImage = {};
  //   try {
  //     const data = await imageUploadApi(`upload`, datakey);
  //     //console.log(data, "data from api heat");
  //     if (data.error === true) {
  //       toast.warning(data.message);
  //       return null;
  //     }

  //     data.data.map((item) => {
  //       //console.log(
  //         `${item.originalname}     and    ${image.profileImgfile.name}  : ${
  //           image.profileImgfile.name == item.originalname
  //         }`
  //       );
  //       if (item.originalname == image.profileImgfile.name) {
  //         setimage({ ...image, profileImglink: item.path });
  //         //console.log(image.profileImglink, "saved user image");
  //       }
  //       if (item.originalname == image.nidimgfile.name) {
  //         setimage({ ...image, nidimglink: item.path });
  //         //console.log("saved nid image");
  //       }
  //       if (item.originalname == image.passportimgfile.name) {
  //         setimage({ ...image, passportimglink: item.path });
  //       }
  //       if (item.originalname == image.dlicensefile.name) {
  //         setimage({ ...image, dlicenselink: item.path });
  //       }
  //       if (item.originalname == image.tinimgfile.name) {
  //         setimage({ ...image, tinimglink: item.path });
  //       }
  //     });
  //     //console.log(image);

  //     // if (data.success === true) {
  //     //   toast.success("সফলভাবে ছবি আপলোড হয়েছে ");
  //     //   await AddgarmentsLabour(image);
  //     // }

  //     // toast.success("সফলভাবে ছবি আপলোড হয়েছে ");
  //     // navigate("/islam");
  //   } catch (err) {
  //     toast.warning(err);
  //   }
  // };
  // //console.log(
  //   image.profileImglink,

  //   // image.nidimglink,

  //   // image.passportimglink,

  //   // image.tinimglink,

  //   // image.dlicenselink,

  //   "all links before"
  // );

  // //console.log(image);
  const [processing, setProcessing] = useState(false);
  const AddgarmentsLabour = async (data) => {
    // //console.log(value.u_nid, "nid");
    setProcessing(true);
    if (
      // organizationCom.length !== 0 &&
      value.u_name.length !== 0 &&
      value.u_nid !== 0 &&
      division.length !== 0 &&
      district.length !== 0 &&
      permenentdivision.length !== 0 &&
      permenentdistrict.length !== 0
    ) {
      setError(false);
    } else {
      setError(true);
      setProcessing(false);
      toast.warning("দয়া  করে সব ফিল্ড  পূরণ করুন");
      return null;
    }

    if (phone === false) {
      setErrorphone(true);
      setProcessing(false);
      // toast.warning(" wrong number");
      // phoneerror = "wrong number";
      toast.warning("দয়া  করে সঠিক নম্বর দিন ");
      return null;
    }

    const islamicOrgArr = [];

    organizationCom.map((orgname) => {
      islamicOrgArr.push({
        name: orgname,
        type: "labour",
      });
    });

    let otherorg;
    if (value.u_others_org) {
      otherorg = {
        name: value.u_others_org,
        poli_type: "others",
        type: "labour",
      };
    }
    let combinedAllOrgArray = [...allOrg];

    if (otherorg) {
      combinedAllOrgArray = [...combinedAllOrgArray, otherorg];
    }
    const datakey = {
      profileType: "rmg",
      profile: {
        u_name: value.u_name,
        u_type: value.u_type,
        u_pro_img: profileImg.link,
        u_father_name: value.u_father_name,
        u_no_of_children: value.u_no_of_children,
        u_education: value.u_education,
        u_dob: date ? date : "00-00-0000",
        u_nationality: value.u_nationality,
        u_religion: value.u_religion,
        u_profession: value.u_profession,
        u_gender: genderValue,
        u_married_status: marriedValue,
        u_p_division: permenentdivision,
        u_p_district: permenentdistrict,
        u_p_upozilla: value.u_p_upozilla,
        u_p_post_code: value.u_p_post_code,
        u_p_others: value.u_p_post_code,
        u_t_division: division,
        u_t_district: district,
        u_t_upozilla: value.u_t_upozilla,
        u_t_post_code: value.u_t_post_code,
        u_t_others: value.u_t_others,
        u_nid: value.u_nid,
        u_nid_img: u_nid_img.link,
        u_passport: value.u_passport,
        u_passport_img: u_passport_img.link,
        u_d_license: value.u_d_license,
        u_d_license_img: u_d_license_img.link,
        u_tin: value.u_tin,
        u_tin_img: u_tin_img.link,
        u_phone1: value.u_phone1,
        u_phone2: value.u_phone2,
        u_fb_link: value.u_fb_link,
        u_twitter_lnk: value.u_twitter_lnk,
        u_whatsapp_no: value.u_whatsapp_no,
        u_sthabor_property: value.u_sthabor_property,
        u_osthabor_property: value.u_osthabor_property,
        u_family_info: value.u_family_info,
        // u_others_org: value.u_others_org,
        u_anual_income: value.u_anual_income,
        u_tax: value.u_tax,
        u_mamla: value.u_mamla,
        u_car: value.u_car,
        u_travel: value.u_travel,
        u_political: value.u_political,
        u_election: value.u_election,
        u_influence_org: value.u_influence_org,
        u_local_influence: value.u_local_influence,

        u_frnds: value.u_frnds,
        u_enemy: value.u_enemy,
        u_connectivity: value.u_connectivity,
        u_corruption: value.u_corruption,
        u_negatives: value.u_negatives,
        u_others: value.u_others,
        u_comment: value.u_comment,
        organizations: combinedAllOrgArray,
      },
    };
    //console.log(datakey, "datakeys");
    try {
      const data = await getDataApi(`profile`, datakey);
      //console.log(data, "data from api heat");
      if (data.error === true) {
        toast.warning(data.message);
        setProcessing(false);
        return null;
      }
      toast.success("সফলভাবে প্রোফাইল তৈরী  হয়েছে ");
      setProcessing(false);
      navigate("/grament_labour");
    } catch (err) {
      toast.warning(err);
    }
  };

  // Promise.all([uploadImage()])
  //   .then((data) => {
  //     AddgarmentsLabour();
  //   })
  //   .catch((err) => {
  //     //console.log(err);
  //   });
  return (
    <div className="pb-10">
      <div className="  border-2  border-btncolor shadow-md rounded-md font-body">
        <div className="xl:m-2 xl:p-2  text-white">
          <div className="mx-2 xl:mx-4">
            <h1 className="mb-6 text-base md:text-lg xl:text-2xl font-mono text-center">
              একটি নতুন প্রোফাইল যুক্ত করুন
            </h1>{" "}
            {/*/////////////////////////////////////////////// personal info-///////////////////////////////////////////////////////////// */}
            <div className=" mt-3">
              <h2 className=" text-base xl:text-xl m-2">ব্যক্তিগত তথ্য</h2>

              <div className="  border  border-btncolor shadow-lg  rounded-lg p-3 grid grid-cols-1 xl:grid-cols-2 gap-x-5">
                <div>
                  <h2 className=" text-sm xl:text-lg mt-1">নাম</h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    required
                    type="text"
                    id="u_name"
                    name="u_name"
                    onChange={handleChange}
                    placeholder="নাম লিখুন"
                  />
                  {error === true ? (
                    <p className=" text-red-600 text-sm font-semibold py-2">
                      {value.u_name.length === 0 ? "নাম অবশ্যই দিতে হবে" : ""}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg mt-1">পিতার নাম</h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    required
                    type="text"
                    id="u_father_name"
                    name="u_father_name"
                    onChange={handleChange}
                    placeholder="পিতার নাম লিখুন"
                  />
                </div>
                {/* <div>
                <h2 className=" text-sm xl:text-lg mt-1">মাতার নাম</h2>
                <textarea
                  className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                  type="text"
                  id="u_mother_name"
                  name="u_mother_name"
                  onChange={handleChange}
                  placeholder="মাতার নাম লিখুন"
                />
              </div> */}

                <div>
                  <h2 className=" text-sm xl:text-lg mt-1">শিক্ষাগত যোগ্যতা</h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    required
                    type="text"
                    id="u_education"
                    name="u_education"
                    onChange={handleChange}
                    placeholder="এসএসসি, এইচএসসি, অনার্স ....."
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg mt-1">জন্ম তারিখ</h2>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      className=" bg-white w-full rounded-xl py-2 "
                      placeholder="জন্ম তারিখ"
                      maxDate={new Date()}
                      value={date}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg mt-1">লিঙ্গ</h2>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={genderValue}
                      onChange={handlegenderChange}
                    >
                      <div className="flex flex-row justify-between items-center">
                        <FormControlLabel
                          value="পুরুষ"
                          control={<Radio color="secondary" />}
                          label="পুরুষ"
                        />
                        <FormControlLabel
                          value="মহিলা"
                          control={<Radio color="secondary" />}
                          label="মহিলা"
                        />
                        <FormControlLabel
                          value="অন্যান্য"
                          control={<Radio color="secondary" />}
                          label="অন্যান্য"
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg mt-1">বৈবাহিক অবস্থা</h2>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={marriedValue}
                      onChange={handleMarriedStatusChange}
                      className="flex justify-between items-center"
                    >
                      <div className="flex flex-row justify-between items-center">
                        <FormControlLabel
                          value="বিবাহিত"
                          control={<Radio color="secondary" />}
                          label="বিবাহিত"
                        />
                        <FormControlLabel
                          value="অবিবাহিত"
                          control={<Radio color="secondary" />}
                          label="অবিবাহিত"
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg mt-1">জাতীয়তা</h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    required
                    id="u_nationality"
                    name="u_nationality"
                    onChange={handleChange}
                    placeholder="বাংলাদেশী....."
                  />
                </div>
                <div className="">
                  <h2 className=" text-sm xl:text-lg mt-1"> ধর্ম :</h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    required
                    id="u_religion"
                    name="u_religion"
                    onChange={handleChange}
                    placeholder="ধর্ম লিখুন"
                  />
                  {/* <h2 className=" text-lg w-full p-2 mt-2 bg-white  text-black rounded-xl ">
                    হিন্দু
                  </h2> */}
                </div>
                <div className="">
                  <h2 className=" text-sm xl:text-lg mt-1">
                    {" "}
                    প্রোফাইলের ধরন :
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    required
                    id="u_type"
                    name="u_type"
                    defaultValue="শ্রমিক"
                    readOnly
                    onChange={handleChange}
                    placeholder="শ্রমিক....."
                  />
                  {/* <h2 className=" text-lg w-full p-2 mt-2 bg-white  text-black rounded-xl ">
                    হিন্দু
                  </h2> */}
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg mt-1">পেশা </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    required
                    type="text"
                    id="u_profession"
                    name="u_profession"
                    onChange={handleChange}
                    placeholder="পেশা লিখুন"
                  />
                </div>
                {marriedValue === "বিবাহিত" && (
                  <div>
                    <h2 className=" text-sm xl:text-lg mt-1">
                      ছেলেমেয়ে সংখ্যা{" "}
                    </h2>
                    <textarea
                      className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                      type="number"
                      id="u_no_of_children"
                      name="u_no_of_children"
                      onChange={handleChange}
                      placeholder="ছেলেমেয়ে সংখ্যা লিখুন"
                      min="0"
                      onKeyPress={preventMinus}
                      onPaste={preventPasteNegative}
                    />
                  </div>
                )}

                {/* image */}
                <div className="my-3 xl:mt-10">
                  <label htmlFor="profileImg">
                    <input
                      style={{ display: "none" }}
                      id="profileImg"
                      name="profileImg"
                      onChange={onProfileImgChange}
                      type="file"
                    />
                    <Button
                      style={{
                        fontSize: "18px",
                        padding: "6px",
                        width: "100%",
                      }}
                      // className=" text-sm  xl:text-xl p-2 w-full"
                      color="secondary"
                      variant="contained"
                      component="span"
                    >
                      একটি প্রোফাইল ছবি আপলোড করুন
                    </Button>
                  </label>
                  <div>
                    {profileImg?.file ? (
                      <div> ফাইলের নাম : {profileImg?.file}</div>
                    ) : null}
                  </div>
                  {imageValidationStatus.proImg === false ? (
                    <p className=" text-red-600 text-xs font-semibold py-2">
                      {imgErrorMsg}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/*/////////////////////////////////////////////// Address info-///////////////////////////////////////////////////////////// */}
            <div className=" mt-3">
              <h2 className=" text-lg xl:text-xl m-2"> ঠিকানা</h2>

              <div className=" border  border-btncolor shadow-lg  rounded-lg p-3 xl:grid  xl:grid-cols-2 gap-x-5">
                <h2 className=" text-lg xl:text-xl col-span-2">
                  বর্তমান ঠিকানা
                </h2>
                <div className="w-full ">
                  <h2 className=" text-sm xl:text-lg my-2">বিভাগ </h2>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={division}
                      className=" bg-white rounded-xl"
                      onChange={(event) => {
                        setDivision(event.target.value);
                      }}
                      displayEmpty={true}
                      renderValue={(value) =>
                        value?.length ? value : "বিভাগ বাছাই করুন"
                      }
                    >
                      {divisionData.map((elem) => (
                        <MenuItem key={elem.id} value={elem.bn_name}>
                          {elem.bn_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {error === true ? (
                    <p className=" text-red-600 text-sm font-semibold py-2">
                      {division.length !== 0 ? null : "বিভাগ অবশ্যই দিতে হবে"}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="w-full ">
                  <h2 className=" text-sm xl:text-lg my-2">জেলা </h2>
                  <FormControl fullWidth>
                    <Select
                      value={district}
                      className=" bg-white rounded-xl"
                      onChange={(event) => {
                        setDistrict(event.target.value);
                      }}
                      displayEmpty={true}
                      renderValue={(value) =>
                        value?.length ? value : "জেলা বাছাই করুন"
                      }
                    >
                      {districtData?.map((elem) => (
                        <MenuItem key={elem.id} value={elem.bn_name}>
                          {elem.bn_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {error === true ? (
                    <p className=" text-red-600 text-sm font-semibold py-2">
                      {district.length !== 0 ? null : "জেলা অবশ্যই দিতে হবে"}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg my-2">উপজেলা / থানা </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    required
                    type="text"
                    id="u_t_upozilla"
                    name="u_t_upozilla"
                    onChange={handleChange}
                    placeholder="উপজেলা / থানা লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg my-2">ডাক ঘর </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    required
                    type="text"
                    id="u_t_post_code"
                    name="u_t_post_code"
                    onChange={handleChange}
                    placeholder="ডাক ঘর লিখুন"
                  />
                </div>
                <div className=" md:col-span-2">
                  <h2 className=" text-sm xl:text-lg my-2">
                    অন্যান্য ঠিকানা লিখুন{" "}
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    rows="2"
                    cols="50"
                    required
                    id="u_t_others"
                    name="u_t_others"
                    onChange={handleChange}
                    placeholder="গ্রাম/এরিয়া, বাড়ি/বাসা নং, রোড নং ....."
                  />
                </div>
                <h2 className="text-lg xl:text-xl col-span-2 my-3 ">
                  স্থায়ী ঠিকানা
                </h2>
                <div>
                  <h2 className=" text-sm xl:text-lg my-2">বিভাগ </h2>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={permenentdivision}
                      className=" bg-white rounded-xl"
                      onChange={(event) => {
                        setPermenentDivision(event.target.value);
                      }}
                      displayEmpty={true}
                      renderValue={(value) =>
                        value?.length ? value : "বিভাগ বাছাই করুন"
                      }
                    >
                      {permenentdivisionData.map((elem) => (
                        <MenuItem key={elem.id} value={elem.bn_name}>
                          {elem.bn_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {error === true ? (
                    <p className=" text-red-600 text-sm font-semibold py-2">
                      {permenentdivision.length !== 0
                        ? null
                        : "বিভাগ অবশ্যই দিতে হবে"}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg my-2">জেলা </h2>
                  <FormControl fullWidth>
                    <Select
                      value={permenentdistrict}
                      className=" bg-white rounded-xl"
                      onChange={(event) => {
                        setPermenentDistrict(event.target.value);
                      }}
                      displayEmpty={true}
                      renderValue={(value) =>
                        value?.length ? value : "জেলা বাছাই করুন"
                      }
                    >
                      {permenentdistrictData?.map((elem) => (
                        <MenuItem key={elem.id} value={elem.bn_name}>
                          {elem.bn_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {error === true ? (
                    <p className=" text-red-600 text-sm font-semibold py-2">
                      {permenentdistrict.length !== 0
                        ? null
                        : "জেলা অবশ্যই দিতে হবে"}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg my-2">উপজেলা/ থানা </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    required
                    type="text"
                    id="u_p_upozilla"
                    name="u_p_upozilla"
                    onChange={handleChange}
                    placeholder="উপজেলা / থানা লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg my-2">ডাক ঘর </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    required
                    type="text"
                    id="u_p_post_code"
                    name="u_p_post_code"
                    onChange={handleChange}
                    placeholder="ডাক ঘর লিখুন"
                  />
                </div>
                <div className=" md:col-span-2">
                  <h2 className=" text-sm xl:text-lg my-2">
                    অন্যান্য ঠিকানা লিখুন{" "}
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    rows="2"
                    cols="50"
                    required
                    id="u_p_others"
                    name="u_p_others"
                    onChange={handleChange}
                    placeholder="গ্রাম/এরিয়া, বাড়ি/বাসা নং, রোড নং ....."
                  />
                </div>
              </div>
            </div>
            {/*//////////////////////////// Identification info-///////////////////////////////////////////////////////////// */}
            <div className=" mt-3">
              <h2 className="text-lg xl:text-xl m-2">আইডেন্টিফিকেশন নম্বর</h2>

              <div className=" border  border-btncolor shadow-lg  rounded-lg p-3 grid grid-cols-1 xl:grid-cols-2 gap-x-5">
                <div>
                  <h2 className=" text-sm xl:text-lg">জাতীয় পরিচয়পত্র</h2>
                  <div className=" flex items-center gap-x-2">
                    <textarea
                      className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                      required
                      type="number"
                      min="0"
                      onKeyPress={preventMinus}
                      onPaste={preventPasteNegative}
                      id="u_nid"
                      name="u_nid"
                      onChange={handleChange}
                      placeholder="জাতীয় পরিচয়পত্র লিখুন"
                    />

                    <label htmlFor="u_nid_img">
                      <input
                        style={{ display: "none" }}
                        id="u_nid_img"
                        name="u_nid_img"
                        onChange={onNid_imgChange}
                        type="file"
                      />
                      <Button
                        style={
                          {
                            // padding: "10px",
                            // width: "100%",
                          }
                        }
                        className=" px-3 py-5 "
                        // color="secondary"
                        variant="contained"
                        component="span"
                      >
                        <AddPhotoAlternateOutlinedIcon />
                      </Button>
                    </label>
                  </div>
                  <div>
                    {u_nid_img?.file ? (
                      <div className=" text-right">
                        ফাইলের নাম : {u_nid_img?.file}
                      </div>
                    ) : null}
                  </div>
                  {imageValidationStatus.nidImg === false ? (
                    <p className=" text-red-600 text-xs font-semibold py-2">
                      {imgErrorMsg}
                    </p>
                  ) : (
                    <></>
                  )}
                  {error === true ? (
                    <p className=" text-red-600 text-sm font-semibold py-2">
                      {value.u_nid !== 0
                        ? ""
                        : "জাতীয় পরিচয়পত্র নম্বর অবশ্যই দিতে হবে"}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">পাসপোর্ট নম্বর</h2>

                  <div className=" flex items-center gap-x-2">
                    <textarea
                      className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                      type="text"
                      id="u_passport"
                      name="u_passport"
                      onChange={handleChange}
                      placeholder="পাসপোর্ট নম্বর লিখুন"
                    />

                    <label htmlFor="u_passport_img">
                      <input
                        style={{ display: "none" }}
                        id="u_passport_img"
                        name="u_passport_img"
                        onChange={on_passport_imgChange}
                        type="file"
                      />
                      <Button
                        style={
                          {
                            // padding: "10px",
                            // width: "100%",
                          }
                        }
                        className=" px-3 py-5 "
                        // color="secondary"
                        variant="contained"
                        component="span"
                      >
                        <AddPhotoAlternateOutlinedIcon />
                      </Button>
                    </label>
                  </div>
                  <div className=" text-right">
                    {u_passport_img?.file ? (
                      <div> ফাইলের নাম : {u_passport_img?.file}</div>
                    ) : null}
                  </div>
                  {imageValidationStatus.passportImg === false ? (
                    <p className=" text-red-600 text-xs font-semibold py-2">
                      {imgErrorMsg}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>

                <div>
                  <h2 className=" text-sm xl:text-lg mt-1">
                    ড্রাইভিং লাইসেন্স নম্বর
                  </h2>

                  <div className=" flex items-center gap-x-2">
                    <textarea
                      className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                      type="text"
                      id="u_d_license"
                      name="u_d_license"
                      onChange={handleChange}
                      placeholder="ড্রাইভিং লাইসেন্স নম্বর লিখুন"
                    />

                    <label htmlFor="u_d_license_img">
                      <input
                        style={{ display: "none" }}
                        id="u_d_license_img"
                        name="u_d_license_img"
                        onChange={onu_d_license_imgChange}
                        type="file"
                      />
                      <Button
                        style={
                          {
                            // padding: "10px",
                            // width: "100%",
                          }
                        }
                        className=" px-3 py-5 "
                        // color="secondary"
                        variant="contained"
                        component="span"
                      >
                        <AddPhotoAlternateOutlinedIcon />
                      </Button>
                    </label>
                  </div>
                  <div className=" text-right">
                    {u_d_license_img?.file ? (
                      <div> ফাইলের নাম : {u_d_license_img?.file}</div>
                    ) : null}
                  </div>
                  {imageValidationStatus.dLicense === false ? (
                    <p className=" text-red-600 text-xs font-semibold py-2">
                      {imgErrorMsg}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>

                <div>
                  <h2 className=" text-sm xl:text-lg mt-1">টিআইএন নম্বর </h2>

                  <div className=" flex items-center gap-x-2">
                    <textarea
                      className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                      type="text"
                      id="u_tin"
                      name="u_tin"
                      onChange={handleChange}
                      placeholder="টিআইএন নম্বর  লিখুন"
                    />

                    <label htmlFor="u_tin_img">
                      <input
                        style={{ display: "none" }}
                        id="u_tin_img"
                        name="u_tin_img"
                        onChange={onu_tin_imgChange}
                        type="file"
                      />
                      <Button
                        style={
                          {
                            // padding: "10px",
                            // width: "100%",
                          }
                        }
                        className=" px-3 py-5 "
                        // color="secondary"
                        variant="contained"
                        component="span"
                      >
                        <AddPhotoAlternateOutlinedIcon />
                      </Button>
                    </label>
                  </div>
                  <div className=" text-right">
                    {u_tin_img?.file ? (
                      <div> ফাইলের নাম : {u_tin_img?.file}</div>
                    ) : null}
                  </div>
                  {imageValidationStatus.tinImg === false ? (
                    <p className=" text-red-600 text-xs font-semibold py-2">
                      {imgErrorMsg}
                    </p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            {/*////////////////////// Social and contact info-///////////////////////////////////////////////////////////// */}
            <div className=" mt-3">
              <h2 className="text-lg xl:text-xl m-2">যোগাযোগ মাধ্যম </h2>
              <div className=" border  border-btncolor shadow-lg  rounded-lg p-3 xl:grid  xl:grid-cols-2 gap-x-5">
                <div className="">
                  <h2 className=" text-sm xl:text-lg">প্রথম মোবাইল নম্বর </h2>
                  <input
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    required
                    type="number"
                    min="0"
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    id="u_phone1"
                    name="u_phone1"
                    onChange={handleChange}
                    placeholder="প্রথম  মোবাইল নম্বর  লিখুন"
                  />
                  {errorphone === false ? null : (
                    <span className="  text-red-600 text-sm font-semibold py-2 ">
                      {phone === false ? (
                        <>প্রথম মোবাইল নাম্বার টি সঠিক নয় </>
                      ) : null}{" "}
                    </span>
                  )}
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">দ্বিতীয় মোবাইল নম্বর</h2>
                  <input
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="number"
                    id="u_phone2"
                    min="0"
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    name="u_phone2"
                    onChange={handleChange}
                    placeholder="দ্বিতীয় মোবাইল নম্বর লিখুন"
                  />
                  {errorphone === false ? null : (
                    <span className=" text-yellow-500  text-sm font-semibold py-2 ">
                      {phone2 === false ? (
                        <>দ্বিতীয় মোবাইল নাম্বার টি সঠিক নয় </>
                      ) : (
                        <></>
                      )}{" "}
                    </span>
                  )}
                </div>
                <h2 className=" md:col-span-2 text-sm xl:text-lg my-2">
                  সোশ্যাল মিডিয়া লিংক
                </h2>
                <div className=" ">
                  <h2 className=" text-sm xl:text-lg">
                    ফেসবুক লিংক <FacebookIcon />
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_fb_link"
                    name="u_fb_link"
                    onChange={handleChange}
                    placeholder="ফেসবুক লিংক লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">
                    টুইটার লিংক <TwitterIcon />
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_twitter_lnk"
                    name="u_twitter_lnk"
                    onChange={handleChange}
                    placeholder="টুইটার লিংক লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">
                    হোয়াটসঅ্যাপ নম্বর <WhatsAppIcon />
                  </h2>
                  <input
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="number"
                    min="0"
                    onKeyPress={preventMinus}
                    onPaste={preventPasteNegative}
                    id="u_whatsapp_no"
                    name="u_whatsapp_no"
                    onChange={handleChange}
                    placeholder="হোয়াটসঅ্যাপ নম্বরলিখুন"
                  />
                  {errorphone === false ? null : (
                    <span className="text-yellow-500  text-sm font-semibold py-2 ">
                      {whatsapp === false ? (
                        <>হোয়াটসঅ্যাপ নাম্বার টি সঠিক নয় </>
                      ) : (
                        <></>
                      )}{" "}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {/*/////////////////////////////////////////////// Asset info-///////////////////////////////////////////////////////////// */}
            <div className=" mt-3">
              <h2 className="text-lg xl:text-xl m-2"> সম্পদ এর বিবরণ </h2>

              <div className=" border  border-btncolor shadow-lg  rounded-lg p-3 grid grid-cols-1 xl:grid-cols-2 gap-x-5">
                <div>
                  <h2 className=" text-sm xl:text-lg">স্থাবর সম্পদ</h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_sthabor_property"
                    name="u_sthabor_property"
                    onChange={handleChange}
                    placeholder="স্থাবর সম্পদ লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">অস্থাবর সম্পদ </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_osthabor_property"
                    name="u_osthabor_property"
                    onChange={handleChange}
                    placeholder="অস্থাবর সম্পদ লিখুন"
                  />
                </div>
              </div>
            </div>
            {/*/////////////////////////////////////////////// Songhthon info-///////////////////////////////////////////////////////////// */}
            <div className=" mt-3">
              <h2 className="text-lg xl:text-xl m-2"> সংগঠন সংক্রান্ত </h2>

              <div className=" border  border-btncolor shadow-lg  rounded-lg p-3 xl:grid xl:grid-cols-2 gap-x-5">
                <div className=" col-span-2">
                  <h2 className=" text-sm xl:text-lg my-2"> শ্রমিক সংগঠন</h2>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      className=" bg-white rounded-xl"
                      value={organizationCom}
                      // value={organizationCom.map((com) => {
                      //   return [com.bn_name];
                      // })}
                      onChange={handleCommunityChange}
                      // input={<OutlinedInput label="Tag" />}

                      displayEmpty={true}
                      renderValue={(value) =>
                        value?.length
                          ? Array.isArray(value)
                            ? value.join(", ")
                            : value
                          : "শ্রমিক সংগঠন ভিত্তিক প্রোফাইল দেখার জন্য নির্বাচন করুন"
                      }
                      // MenuProps={MenuProps}
                    >
                      {organizationComData.map((elem) => (
                        <MenuItem key={elem.id} value={elem.name}>
                          <Checkbox
                            checked={organizationCom.indexOf(elem.name) > -1}
                          />
                          <ListItemText primary={elem.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {/* {error === true ? (
                    <p className=" text-red-600 text-sm font-semibold py-2">
                      {organizationCom.length !== 0
                        ? null
                        : "কমপক্ষে ১টি সংগঠন দিতে হবে"}
                    </p>
                  ) : (
                    <></>
                  )} */}
                </div>

                <div className=" col-span-2">
                  <h2 className=" text-sm xl:text-lg">অন্যান্য সংগঠন</h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-md"
                    type="text"
                    id="u_others_org"
                    name="u_others_org"
                    onChange={handleChange}
                    placeholder="অন্যান্য  লিখুন"
                  />
                </div>
              </div>
            </div>
            {/*/////////////////////////////////////////////// Others  info-///////////////////////////////////////////////////////////// */}
            <div className=" mt-3">
              <h2 className="text-lg xl:text-xl m-2"> অন্যান্য তথ্য </h2>

              <div className=" border  border-btncolor shadow-lg  rounded-lg p-3 xl:grid  xl:grid-cols-2 gap-5">
                <div>
                  <h2 className=" text-sm xl:text-lg">
                    বার্ষিক আয় এবং আয়ের উৎস{" "}
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    required
                    type="text"
                    id="u_anual_income"
                    name="u_anual_income"
                    onChange={handleChange}
                    placeholder="বার্ষিক আয় এবং আয়ের উৎস লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">আয়কর সংক্রান্ত তথ্য </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_tax"
                    name="u_tax"
                    onChange={handleChange}
                    placeholder="আয়কর সংক্রান্ত  তথ্য  লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">গাড়ি সংক্রান্ত তথ্য </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_car"
                    name="u_car"
                    onChange={handleChange}
                    placeholder="গাড়ি  সংক্রান্ত  তথ্য লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">
                    বিদেশ ভ্রমণ সংক্রান্ত{" "}
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_travel"
                    name="u_travel"
                    onChange={handleChange}
                    placeholder="বিদেশ ভ্রমণ  সংক্রান্ত তথ্য লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">
                    রাজনৈতিক সংশ্লিষ্টতার বিস্তারিত বিবরণ{" "}
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="userId"
                    name="userId"
                    onChange={handleChange}
                    placeholder="রাজনৈতিক সংশ্লিষ্টতার বিস্তারিত বিবরণ  লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">
                    নির্বাচনে অংশগ্রহণ সংক্রান্ত তথ্য{" "}
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_election"
                    name="u_election"
                    onChange={handleChange}
                    placeholder="নির্বাচনে অংশগ্রহণ সংক্রান্ত তথ্য লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">সংগঠনের উপর প্রভাব</h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_influence_org"
                    name="u_influence_org"
                    onChange={handleChange}
                    placeholder="সংগঠনের   উপর  প্রভাব  লিখুন"
                  />
                </div>

                <div>
                  <h2 className=" text-sm xl:text-lg">মামলা সংক্রান্ত তথ্য</h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_mamla"
                    name="u_mamla"
                    onChange={handleChange}
                    placeholder="মামলা সংক্রান্ত তথ্য লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">ঘনিষ্ঠ মিত্রদের নাম </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_frnds"
                    name="u_frnds"
                    onChange={handleChange}
                    placeholder="ঘনিষ্ঠ মিত্রদের নাম  লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">
                    বিরোধী ব্যক্তিদের নাম{" "}
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_enemy"
                    name="u_enemy"
                    onChange={handleChange}
                    placeholder="বিরোধী ব্যক্তিদের  নাম  লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">
                    বহির্বিশ্ব এর সাথে যোগাযোগ{" "}
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_connectivity"
                    name="u_connectivity"
                    onChange={handleChange}
                    placeholder="বহির্বিশ্ব এর সাথে  যোগাযোগ   লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">
                    নেতিবাচক বক্তব্য ও কর্মকান্ড{" "}
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_negatives"
                    name="u_negatives"
                    onChange={handleChange}
                    placeholder="নেতিবাচক বক্তব্য ও  কর্মকান্ড  লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">
                    স্থানীয় প্রভাব ও প্রতিপত্তি সংক্রান্ত তথ্যাদি{" "}
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    id="u_local_influence"
                    name="u_local_influence"
                    onChange={handleChange}
                    placeholder="স্থানীয় প্রভাব ও প্রতিপত্তি সংক্রান্ত তথ্যাদি লিখুন"
                  />
                </div>
                <div>
                  <h2 className=" text-sm xl:text-lg">
                    দুর্নীতি, চরমপন্থী, জঙ্গিবাদ ও নাশকতামূলক সংক্রান্ত তথ্যাদি{" "}
                  </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    required
                    id="u_corruption"
                    name="u_corruption"
                    onChange={handleChange}
                    placeholder="দুর্নীতি, চরমপন্থী, জঙ্গিবাদ ও নাশকতামূলক সংক্রান্ত তথ্যাদি লিখুন"
                  />
                </div>
                <div className=" md:col-span-2">
                  <h2 className=" text-sm xl:text-lg">পারিবারিক তথ্যাদি </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    rows="4"
                    cols="50"
                    id="u_family_info"
                    name="u_family_info"
                    onChange={handleChange}
                    placeholder="পারিবারিক তথ্যাদি লিখুন"
                  />
                </div>
                <div className=" md:col-span-2">
                  <h2 className=" text-sm xl:text-lg">অন্যান্য তথ্যাদি </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    rows="4"
                    cols="50"
                    id="u_others"
                    name="u_others"
                    onChange={handleChange}
                    placeholder="অন্যান্য তথ্যাদি  লিখুন"
                  />
                </div>

                <div className=" md:col-span-2">
                  <h2 className=" text-sm xl:text-lg">মন্তব্য </h2>
                  <textarea
                    className="w-full p-2 mt-2 bg-white text-sm xl:text-lg text-black rounded-xl"
                    type="text"
                    rows="4"
                    cols="50"
                    id="u_comment"
                    name="u_comment"
                    onChange={handleChange}
                    placeholder="মন্তব্য লিখুন"
                  />
                </div>
              </div>
            </div>
            <div className="mx-4">
              {processing === true ? (
                <button
                  type="button"
                  className="w-full   my-4 mt-8 px-10 rounded-xl bg-purple-700   text-center"
                  disabled
                >
                  <span className=" flex justify-center items-center gap-x-6">
                    {" "}
                    <BtnLoader />
                    প্রক্রিয়া করা হচ্ছে, অনুগ্রহ করে অপেক্ষা করুন
                  </span>
                  {/* <svg
                    className="animate-spin text-white spinner-border h-5 w-5 mr-3"
                    viewBox="0 0 24 24"
                  ></svg> */}
                </button>
              ) : (
                <button
                  onClick={AddgarmentsLabour}
                  // type="submit"
                  className="w-full py-4 my-4 mt-8 px-10 rounded-xl bg-purple-600 hover:bg-purple-700 text-white text-lg text-center"
                >
                  জমা দিন
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddgarmentsLabour;
